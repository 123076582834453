import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {BarActions} from "../../store/Bar";
import MainLayout from "../../components/Layout/MainLayout";
import BarMenu from "../../components/bar/BarMenu";
import BarInformationContainer from "../../components/bar/BarInformationContainer";
import BarIntroduceContainer from "../../components/bar/BarIntroduceContainer";
import BarMenuContainer from "../../components/bar/BarMenuContainer";
import BarTagContainer from "../../components/bar/BarTagContainer";
import BarOpeningHourContainer from "../../components/bar/BarOpeningHourContainer";
import LoadingDialog from "../../components/Dialog/LoadingDialog";

const BarPage = () => {
    const dispatch = useDispatch();
    const {bar, isLoading} = useSelector((state) => ({
        bar: state.bar.ui.bar,
        isLoading: state.bar.ui.isLoading,
    }));

    const {state} = useState();
    const {barId} = useParams();
    const barImageState = useState();

    useEffect(() => {
        if (state) {
            dispatch(BarActions.setBar(state));
            return;
        }

        dispatch(BarActions.getBar(barId));
    }, []);

    useEffect(() => {
        return () => {
            dispatch(BarActions.clearBar());
        }
    }, []);

    return (
        <MainLayout key={bar} title={bar?.id ? `#${bar?.id}` : ''} menu={<BarMenu barImageState={barImageState}/>}>
            <BarInformationContainer barImageState={barImageState}/>
            <BarIntroduceContainer/>
            <BarTagContainer/>
            <BarMenuContainer/>
            <BarOpeningHourContainer/>

            <LoadingDialog open={isLoading}/>
        </MainLayout>
    );
}

export default BarPage;