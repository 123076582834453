import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import api from "../lib/api";
import {createAction, createReducer} from "@reduxjs/toolkit";

const GET_BAR_LIST = 'bar/GET_BAR_LIST';
const GET_NO_IMAGE_BAR_LIST = 'bar/GET_NO_IMAGE_BAR_LIST';
const GET_BAR = 'bar/GET_BAR';
const GET_OPENING_HOUR = 'bar/GET_OPENING_HOUR';
const POST_BAR = 'bar/POST_BAR';
const PUT_BAR = 'bar/PUT_BAR';
const PUT_MENU = 'bar/PUT_MENU';
const PUT_TAG = 'bar/PUT_TAG';
const PUT_OPENING_HOUR = 'bar/PUT_OPENING_HOUR';
const DELETE_BAR = 'bar/DELETE_BAR';
const SET_BAR = 'bar/SET_BAR';
const SET_OPENING_HOUR = 'bar/SET_OPENING_HOUR';
const DELETE_OPENING_HOUR = 'bar/DELETE_OPENING_HOUR';
const CLEAR_BAR = 'bar/CLEAR_BAR';
const CLEAR_ERROR = 'bar/CLEAR_ERROR';

const initialState = {
    data: {
        bar: [],
        noImage: [],
        categories: undefined,
        count: 0,
    },
    ui: {
        isLoading: false,
        bar: undefined,
        openingHour: [],
    },
    error: {
        openingHour: '',
        postBar: '',
    },
};

const getBarListActions = makeAsyncActionCreator(GET_BAR_LIST);
const getNoImageBarListActions = makeAsyncActionCreator(GET_NO_IMAGE_BAR_LIST);
const getBarActions = makeAsyncActionCreator(GET_BAR);
const getOpeningHourActions = makeAsyncActionCreator(GET_OPENING_HOUR);
const postBarActions = makeAsyncActionCreator(POST_BAR);
const putBarActions = makeAsyncActionCreator(PUT_BAR);
const putMenuActions = makeAsyncActionCreator(PUT_MENU);
const putTagActions = makeAsyncActionCreator(PUT_TAG);
const putOpeningHourActions = makeAsyncActionCreator(PUT_OPENING_HOUR);
const deleteBarActions = makeAsyncActionCreator(DELETE_BAR);

const getBarList = (query) => async dispatch => {
    dispatch(getBarListActions.INDEX());

    try {
        const response = await api.getBarList(query);
        dispatch(getBarListActions.SUCCESS(response));
    } catch (e) {
        dispatch(getBarListActions.FAIL({error: e}));
    }
}

const getNoImageBarList = (query) => async dispatch => {
    dispatch(getNoImageBarListActions.INDEX());

    try {
        const response = await api.getBarList(query);
        dispatch(getNoImageBarListActions.SUCCESS(response));
    } catch (e) {
        dispatch(getNoImageBarListActions.FAIL({error: e}));
    }
}

const getBar = (id) => async dispatch => {
    dispatch(getBarActions.INDEX());

    try {
        const response = await api.getBar(id);
        dispatch(getBarActions.SUCCESS(response));
    } catch (e) {
        dispatch(getBarActions.FAIL({error: e}));
    }
}

const getOpeningHour = (id) => async dispatch => {
    dispatch(getOpeningHourActions.INDEX());

    try {
        const response = await api.getOpeningHours(id);
        dispatch(getOpeningHourActions.SUCCESS(response));
    } catch (e) {
        dispatch(getOpeningHourActions.FAIL({error: e}));
    }
}

const postBar = (request) => async dispatch => {
    try {
        const parsedRequest = {
            ...request,
            location: request.location === 'null' ? undefined : request.location,
        };

        dispatch(postBarActions.INDEX(parsedRequest));

        await api.createBar(parsedRequest);
        dispatch(postBarActions.SUCCESS());
    } catch (e) {
        dispatch(postBarActions.FAIL({error: e}));
        return e;
    }
}

const putBar = (request, barImage) => async dispatch => {
    dispatch(putBarActions.INDEX());

    try {
        if (barImage) {
            request.image_id = await api.uploadImage(barImage, 1);
        }

        const response = await api.updateBar(request);
        dispatch(putBarActions.SUCCESS(response));
    } catch (e) {
        dispatch(putBarActions.FAIL({error: e}));
    }
}

const putMenu = (newMenuList) => async (dispatch, state) => {
    dispatch(putMenuActions.INDEX());

    try {
        const bar = state().bar.ui.bar || {};

        await Promise.all(newMenuList.map((e, index) => {
            const originalMenu = (bar.menu ?? [])[index];

            if (e !== originalMenu) {
                const request = {
                    bar_id: bar.id,
                    original_name: originalMenu,
                    name: e
                };

                return api.updateBarMenu(request);
            } else return undefined
        }));

        const filteredMenuList = newMenuList.filter((e) => e);
        dispatch(putMenuActions.SUCCESS(filteredMenuList));
        return filteredMenuList
    } catch (e) {
        dispatch(putBarActions.FAIL({error: e}));
    }
}

const putTag = (newTagList) => async (dispatch, state) => {
    dispatch(putTagActions.INDEX());

    try {
        const bar = state().bar.ui.bar || {};

        await Promise.all(newTagList.map((e, index) => {
            const originalTag = (bar.tag ?? [])[index];

            if (e !== originalTag) {
                const request = {
                    bar_id: bar?.id,
                    original_tag: originalTag,
                    tag: e
                };

                return api.updateBarTag(request);
            } else return undefined
        }));

        const filteredTagList = newTagList.filter((e) => e);
        dispatch(putTagActions.SUCCESS(filteredTagList));
        return filteredTagList
    } catch (e) {
        dispatch(putBarActions.FAIL({error: e}));
    }
}

const putOpeningHour = (barId) => async (dispatch, state) => {
    try {
        const requestOpeningHours = [...state().bar.ui.openingHour];
        const lastOpeningHour = {...requestOpeningHours.pop()};

        if (Object.keys(lastOpeningHour).length > 0) {
            if (!lastOpeningHour.bar_id) {
                lastOpeningHour.bar_id = barId;
            }

            requestOpeningHours.push(lastOpeningHour);
        }

        await dispatch(putOpeningHourActions.INDEX(requestOpeningHours));

        await api.deleteOpeningHours(barId);
        await Promise.all(
            requestOpeningHours.map((e) => api.createOpeningHours(e))
        )

        const {opening_hours} = await api.getBar(barId);
        dispatch(putOpeningHourActions.SUCCESS(opening_hours));
    } catch (e) {
        dispatch(putOpeningHourActions.FAIL({error: e}));
        return e;
    }
}

const deleteBar = (id) => async dispatch => {
    dispatch(deleteBarActions.INDEX());

    try {
        await api.deleteBar(id);
        dispatch(deleteBarActions.SUCCESS());
    } catch (e) {
        dispatch(deleteBarActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_BAR_LIST, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.bar = action.payload.item;
            state.data.count = action.payload.count;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(GET_NO_IMAGE_BAR_LIST, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.noImage = action.payload.item;
            state.data.count = action.payload.count;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(GET_BAR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.bar = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(GET_OPENING_HOUR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.openingHour = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(POST_BAR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;

            const {location, location_name, name, address} = action.payload;
            if (!((location || location_name) && name && address)) {
                throw Error('입력되지 않은 항목이 있습니다');
            }
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            state.error.postBar = action.payload.error.message;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(PUT_BAR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.bar = action.payload.item;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(PUT_MENU, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.bar.menu = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(PUT_TAG, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.bar.tag = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(PUT_OPENING_HOUR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;

            const openingHours = action.payload;
            openingHours.reduce((acc, e) => {
                const weekday = e.weekday;
                if (weekday && (acc & weekday)) {
                    throw Error('겹치는 요일이 있습니다');
                }

                return acc + weekday;
            }, 0);

            openingHours.forEach((e) => {
                if (!(e.open_time && e.close_time)) {
                    throw Error('설정 안 된 시간이 있습니다');
                }
            });
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
            state.ui.bar.opening_hours = action.payload;
            delete state.error.openingHour;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            state.error.openingHour = action.payload.error.message;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(DELETE_BAR, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    [SET_BAR]: (state, action) => {
        state.ui.bar = action.payload;
    },
    [SET_OPENING_HOUR]: (state, action) => {
        const newOpeningHour = action.payload;
        const targetIndex = state.ui.openingHour.findIndex((e) => e.id === newOpeningHour.id);

        if (targetIndex < 0) {
            state.ui.openingHour.push(newOpeningHour);
            return;
        }

        state.ui.openingHour.splice(targetIndex, 1, newOpeningHour);
    },
    [DELETE_OPENING_HOUR]: (state, action) => {
        const targetId = action.payload;
        const prevOpeningHour = state.ui.openingHour;
        state.ui.openingHour = prevOpeningHour.filter((e) => e.id !== targetId);
    },
    [CLEAR_BAR]: (state, action) => {
        delete state.ui.bar;
        delete state.ui.barImage;
    },
    [CLEAR_ERROR]: (state, action) => {
        state.error = {};
    }
});

export const BarActions = {
    getBarList,
    getNoImageBarList,
    getBar,
    getOpeningHour,
    postBar,
    putBar,
    putMenu,
    putTag,
    putOpeningHour,
    deleteBar,
    setBar: createAction(SET_BAR),
    setOpeningHour: createAction(SET_OPENING_HOUR),
    deleteOpeningHour: createAction(DELETE_OPENING_HOUR),
    clearBar: createAction(CLEAR_BAR),
    clearError: createAction(CLEAR_ERROR),
};

export default reducer;