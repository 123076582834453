import {Dialog} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MaterialTextField from "../MaterialTextField";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import MaterialButton from "../MaterialButton";
import {BarActions} from "../../store/Bar";

const TagDialog = ({open, onClose, ...props}) => {
    const dispatch = useDispatch();
    const tag = useSelector((state) => state.bar.ui.bar?.tag || []);

    const [newTagList, setNewTagList] = useState([...tag]);
    const newTagRef = useRef();

    useEffect(() => {
        if (open) {
            setNewTagList(tag);
        }
    }, [open]);

    const onTagChanged = (index, target) => {
        newTagList[index] = target.value;
    }

    const addTagHandler = () => {
        const newTag = newTagRef.current.value;

        if (newTag && !newTagList.includes(newTag)) {
            setNewTagList((prevTagList) => {
                const tagList = [...prevTagList, newTag];
                newTagRef.current.value = '';
                return tagList
            })
        }
    }

    const deleteTagHandler = (index) => {
        setNewTagList((prevTagList) => {
            const tagList = [...prevTagList];
            tagList[index] = undefined;
            return tagList;
        });
    }

    const onSaveHandler = async () => {
        const response = await dispatch(BarActions.putTag(newTagList));
        setNewTagList(response);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <div css={rootStyle}>
                <span css={tagLabelStyle}>태그 설정</span>
                {newTagList.map((e, index) => e && (
                    <div key={e} css={tagStyle}>
                        <MaterialTextField css={tagNameStyle} key={e} defaultValue={e}
                                           onChange={({target}) => onTagChanged(index, target)}/>
                        <MaterialButton css={deleteTagButtonStyle} color='warning'
                                        onClick={() => deleteTagHandler(index)}>-</MaterialButton>
                    </div>
                ))}
                <div css={tagStyle}>
                    <MaterialTextField css={tagNameStyle} ref={newTagRef} placeholder={'태그 추가'}/>
                    <MaterialButton css={deleteTagButtonStyle} onClick={addTagHandler}>+</MaterialButton>
                </div>
                <div css={buttonsStyle}>
                    <MaterialButton onClick={onClose} color='warning'>취소</MaterialButton>
                    <MaterialButton onClick={onSaveHandler} css={{marginLeft: 4}}>저장</MaterialButton>
                </div>
            </div>
        </Dialog>
    );
}

const rootStyle = css`
  padding: 12px;
`;

const tagLabelStyle = css`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const tagStyle = css`
  display: flex;
  margin-top: 4px;
`;

const tagNameStyle = css`
  flex-grow: 1;
`;

const deleteTagButtonStyle = css`
  margin-left: 4px;
`;

const buttonsStyle = css`
  margin-top: 8px;
  text-align: end;
`;

export default TagDialog;