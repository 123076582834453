import {Dialog, MenuItem} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import React, {useEffect, useRef} from "react";
import {LocationActions} from "../../store/Location";
import {css} from "@emotion/react";
import MaterialSelect from "../MaterialSelect";
import {ApplyActions} from "../../store/Apply";
import MaterialTextField from "../MaterialTextField";
import MaterialButton from "../MaterialButton";
import {useNavigate} from "react-router-dom";
import {BarActions} from "../../store/Bar";
import LoadingDialog from "./LoadingDialog";

const ApplyBarDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {apply, location, isLoading, error} = useSelector((state) => ({
        apply: state.apply.ui.apply,
        location: state.location.data.location,
        isLoading: state.bar.ui.isLoading,
        error: state.bar.error.postBar,
    }));

    const locationNameRef = useRef(apply.location_name);
    const nameRef = useRef(apply.name);
    const addressRef = useRef(apply.address);

    useEffect(() => {
        dispatch(LocationActions.getLocation());
    }, []);

    useEffect(() => {
        dispatch(BarActions.clearError());
    }, [apply]);

    const selectLocationHandler = ({target}) => {
        const newLocation = target.value;
        const newApply = {
            ...apply,
            location: newLocation,
        };

        dispatch(ApplyActions.setApply(newApply));
    }

    const updateApply = () => {
        const newApply = {
            ...apply,
            name: nameRef.current.value,
            address: addressRef.current.value,
        };

        dispatch(ApplyActions.setApply(newApply));
    }

    const deleteApply = async () => {
        await dispatch(ApplyActions.deleteApply(apply.id));
        navigate(0);
    }

    const postBar = async () => {
        const response = await dispatch(BarActions.postBar(apply));

        if (!response) {
            navigate(0);
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <div css={dialogHeaderStyle}>
                <span css={dialogTextStyle}>아래 정보로 등록하시겠습니까?</span>
                <MaterialButton css={{margin: 12}} color={'warning'} onClick={deleteApply}>삭제</MaterialButton>
            </div>

            <div css={addDialogInputContainerStyle}>
                <MaterialSelect css={addDialogInputStyle} value={apply.location || 'null'}
                                onChange={selectLocationHandler}>
                    {location.map((e) => (
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    ))}
                    <MenuItem value='null'>+ 지역 추가</MenuItem>
                </MaterialSelect>
                {(!apply.location || apply.location === 'null') &&
                    <MaterialTextField css={addDialogInputStyle} label={'지역명'} ref={locationNameRef}/>}
                <MaterialTextField css={addDialogInputStyle} label={'매장명'} ref={nameRef} value={apply.name || ''}
                                   onChange={updateApply}/>
                <MaterialTextField css={addDialogInputStyle} label={'주소'} ref={addressRef} value={apply.address || ''}
                                   onChange={updateApply}/>
                {apply.latitude && (<MaterialTextField css={addDialogInputStyle} label={'위경도'}
                                                      value={`${apply.latitude}, ${apply.longitude}`} disabled/>)}
                <MaterialTextField css={addDialogInputStyle} label={'제보일'}
                                   value={moment(apply.created_time).format("YYYY.MM.DD HH:mm:ss")}
                                   disabled/>
                <MaterialTextField css={addDialogInputStyle} label={'제보자'}
                                   value={apply.email || ''} disabled/>
            </div>
            <div css={errorMessageStyle}>{error}</div>
            <div css={dialogButtonBarStyle}>
                <MaterialButton onClick={onClose}>취소</MaterialButton>
                <MaterialButton onClick={postBar} css={{marginLeft: 4}}>등록</MaterialButton>
            </div>
            <LoadingDialog open={isLoading}/>
        </Dialog>
    );
}

const dialogHeaderStyle = css`
  display: flex;
  justify-content: space-between;
`;

const dialogTextStyle = css`
  margin: 20px;
  font-weight: 700;
`;

const addDialogInputContainerStyle = css`
  margin: 0 20px 8px;
  display: flex;
  flex-direction: column;
`;

const addDialogInputStyle = css`
  margin-bottom: 12px;
`;

const errorMessageStyle = css`
  color: red;
  text-align: center;
  margin-bottom: 12px;
`;

const dialogButtonBarStyle = css`
  margin: auto;
  padding-bottom: 10px;
`;

export default ApplyBarDialog;