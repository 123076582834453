import {Select} from "@mui/material";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {forwardRef} from "react";

const selectStyle = css`
  min-width: 80px;
  
  &.MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.primary};
    }
  }
`;

const MaterialSelect = forwardRef(({children, ...props}, ref) => {
    return (
        <Select css={selectStyle} ref={ref} {...props}>
            {children}
        </Select>
    );
});

export default MaterialSelect;