import MainLayout from "../../components/Layout/MainLayout";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NoticeActions} from "../../store/Notice";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import MaterialTextField from "../../components/MaterialTextField";
import MaterialButton from "../../components/MaterialButton";
import CardView from "../../components/CardView";
import {css} from "@emotion/react";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import DeleteDialog from "../../components/Dialog/DeleteDialog";
import {ImageActions} from "../../store/Image";

const NoticePage = () => {
    const dispatch = useDispatch();
    const {state} = useLocation();
    const {noticeId} = useParams();
    const {notice, isLoading} = useSelector((state) => ({
        notice: state.notice.ui.notice,
        isLoading: state.notice.ui.isLoading,
    }));
    const [uploadedImage, setUploadedImage] = useState();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const navigate = useNavigate();
    const titleRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        if (state) {
            dispatch(NoticeActions.setNotice(state));
            return;
        }

        if (!notice && noticeId) {
            dispatch(NoticeActions.getNotice(noticeId));
            return;
        }

        dispatch(NoticeActions.clearNotice());
    }, []);

    const onNoticeUpdated = () => {
        const newNotice = {
            ...notice,
            title: titleRef.current.value,
            content: contentRef.current.value,
        };

        dispatch(NoticeActions.setNotice(newNotice));
    }

    const onFileUpdated = ({target}) => {
        const image = target.files[0];
        setUploadedImage(image);
    }

    const submitButtonHandler = async () => {
        const request = {
            notice_id: notice?.id,
            title: titleRef.current.value,
            content: contentRef.current.value,
        };

        if (uploadedImage) {
            request.image_id = await dispatch(ImageActions.uploadImage(uploadedImage, 2));
        }

        await dispatch(NoticeActions.sendNotice(request))
        navigate('../notice', {replace: true});
    }

    const deleteHandler = async () => {
        await dispatch(NoticeActions.deleteNotice(notice?.id));
        navigate('../notice', {replace: true});
    }

    return (
        <MainLayout title={`공지사항 ${notice ? '수정' : '등록'}`}>
            <CardView>
                <MaterialTextField css={inputStyle} ref={titleRef} label={'제목'} value={notice?.title || ''}
                                   onChange={onNoticeUpdated}/>
                <MaterialTextField css={inputStyle} ref={contentRef} rows={10} value={notice?.content || ''}
                                   onChange={onNoticeUpdated}
                                   multiline/>
                {(notice?.image_id || uploadedImage) && (
                    <img css={imageStyle} src={uploadedImage ?
                        URL.createObjectURL(uploadedImage) :
                        (process.env.REACT_APP_TIPXY_S3_URL + notice?.filename)
                    }/>
                )}
                <MaterialButton variant='outlined' component='label'>
                    사진 첨부
                    <input type='file' accept='image/*' onChange={onFileUpdated} hidden/>
                </MaterialButton>
                <MaterialButton css={submitButtonStyle} onClick={submitButtonHandler}>등록</MaterialButton>
                {notice && <MaterialButton css={submitButtonStyle} onClick={() => setIsDeleteOpen(true)}
                                           color='warning'>삭제</MaterialButton>}
            </CardView>

            <LoadingDialog open={isLoading}/>
            <DeleteDialog open={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}
                          closeDeleteDialogHandler={() => setIsDeleteOpen(false)}
                          deleteHandler={deleteHandler}/>
        </MainLayout>
    );
}


const inputStyle = css`
  margin-bottom: 12px;
`;

const imageStyle = css`
  margin: 0 auto 8px;
  border-radius: 12px;
  width: 240px;
`;

const submitButtonStyle = css`
  margin-top: 8px;
`;


export default NoticePage;