import MaterialButton from "../MaterialButton";
import ChangePasswordDialog from "../Dialog/ChangePasswordDialog";
import React, {useState} from "react";

const UserChangePasswordContainer = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <MaterialButton onClick={() => setOpen(true)} fullWidth>패스워드 변경</MaterialButton>
            <ChangePasswordDialog open={open} onClose={() => setOpen(false)}/>
        </>
    );
}

export default UserChangePasswordContainer;