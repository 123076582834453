import CardView from "../CardView";
import {useSelector} from "react-redux";
import moment from "moment";

const UserDetailContainer = () => {
    const user = useSelector((state) => state.user.ui.user);

    return (
        <CardView title={'상세정보'}>
            <table>
                <tbody>
                <tr>
                    <td>가입일</td>
                    <td>{moment(user?.created_time).format('yyyy년 MM월 DD일')}</td>
                </tr>
                <tr>
                    <td>최근 접속 시각</td>
                    <td>{moment(user?.access_time).format('yyyy년 MM월 DD일 HH:mm:ss')}</td>
                </tr>
                <tr>
                    <td colSpan={2}>{user?.social_name}</td>
                </tr>
                </tbody>
            </table>
        </CardView>
    );
}

export default UserDetailContainer;