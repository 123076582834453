import {Dialog} from "@mui/material";
import MaterialTextField from "../MaterialTextField";
import {css} from "@emotion/react";
import MaterialButton from "../MaterialButton";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef} from "react";
import {UserActions} from "../../store/User";

const ChangePasswordDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const {userId, error} = useSelector((state) => ({
        userId: state.user.ui.user?.id,
        error: state.user.error.password,
    }));

    const newPasswordRef = useRef();
    const confirmRef = useRef();

    useEffect(() => {
        if (open) {
            dispatch(UserActions.clearError());
        }
    }, [open]);

    const updatePassword = async () => {
        const password = newPasswordRef.current.value;
        const confirm = confirmRef.current.value;

        const request = {
            target_user_id: userId,
            password,
            confirm,
        };

        const response = await dispatch(UserActions.putPassword(request));

        if (!response) {
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <div css={passwordTextFieldContainerStyle}>
                <MaterialTextField css={{marginBottom: 12}} ref={newPasswordRef} label={'비밀번호'}/>
                <MaterialTextField ref={confirmRef} label={'비밀번호 확인'}/>
            </div>
            <div css={errorMessageStyle}>{error}</div>
            <div css={passwordButtonContainerStyle}>
                <MaterialButton color={'warning'} onClick={onClose}>취소</MaterialButton>
                <MaterialButton onClick={updatePassword}>확인</MaterialButton>
            </div>
        </Dialog>
    );
}

const passwordTextFieldContainerStyle = css`
  display: flex;
  flex-direction: column;
  padding: 12px;
`;

const passwordButtonContainerStyle = css`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 12px;
`;

const errorMessageStyle = css`
  color: red;
  padding: 0 8px 12px;
  text-align: center;
`;

export default ChangePasswordDialog;