import MaterialButton from "../MaterialButton";
import React, {useState} from "react";
import {css} from "@emotion/react";
import DeleteDialog from "../Dialog/DeleteDialog";

const UserPageButtons = ({onUpdate, onDelete}) => {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const openDeleteDialog = () => setShowDeleteDialog(true)
    const closeDeleteDialog = () => setShowDeleteDialog(false)

    return (
        <div>
            <MaterialButton css={buttonStyle} onClick={openDeleteDialog} color='warning'>삭제</MaterialButton>
            <MaterialButton css={buttonStyle} onClick={onUpdate}>수정</MaterialButton>

            <DeleteDialog open={showDeleteDialog} onClose={closeDeleteDialog}
                          closeDeleteDialogHandler={closeDeleteDialog} deleteHandler={onDelete}/>
        </div>
    );
}

const buttonStyle = css`
  margin-left: 4px;
`;

export default UserPageButtons;