import MainLayout from "../../components/Layout/MainLayout";
import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import MaterialBoard from "../../components/MaterialBoard";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import moment from "moment/moment";
import DeleteDialog from "../../components/Dialog/DeleteDialog";
import api from "../../lib/api";

const ChatRoomPage = () => {
    const {state} = useLocation();
    const {chatRoomId} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [chatRoom, setChatRoom] = useState(state);
    const [chatList, setChatList] = useState([]);

    const [openDelete, setOpenDelete] = useState(false);
    const [clickedChatId, setClickedChatId] = useState();

    const dateFormatter = ({value}) => moment(value).format("HH:mm:ss");

    const columns = [
        {field: 'id', headerName: 'ID', width: 64},
        {field: 'content', headerName: '내용', minWidth: 200, flex: 1},
        {field: 'user_id', headerName: '유저 ID'},
        {field: 'nickname', headerName: '닉네임', width: '160'},
        {field: 'created_time', headerName: '발송 시각', valueFormatter: dateFormatter},
    ];

    const loadMore = (page) => {
        setIsLoading(true);
        api.getChatList(chatRoomId, page)
            .then((response) => {
                setChatList((chatList) => chatList.concat(response));
            }).finally(() => setIsLoading(false));
    }

    const onChatClickHandler = ({row}) => {
        setClickedChatId(row.id)
        setOpenDelete(true);
    }

    const deleteChatHandler = () => {
        setIsLoading(true);
        api.deleteChat(clickedChatId)
            .then(() => {
                setChatList((chatList) => chatList.filter((e) => e.id !== clickedChatId))
            })
            .finally(() => {
                setOpenDelete(false);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        loadMore(0);
    }, []);

    return (
        <MainLayout title={`${chatRoom.name} ${moment(chatRoom.created_time).format("YYYYMMDD")}`}>
            <MaterialBoard columns={columns} rows={chatList} onLoadMore={(page) => loadMore(page)}
                           onRowClick={onChatClickHandler}/>
            <DeleteDialog open={openDelete} onClose={() => setOpenDelete(false)}
                          closeDeleteDialogHandler={() => setOpenDelete(false)} deleteHandler={deleteChatHandler}/>
            <LoadingDialog open={isLoading}/>
        </MainLayout>
    );
}

export default ChatRoomPage;