import {fetchDelete, fetchGet, getQueryString} from "../fetchLib";

export default {
    getChatRoomList: async () => {
        const response = await fetchGet({
            url: `/api/private/admin/chat/room/list`
        });

        return response.item.filter((e) => e.is_opened);
    },
    getChatList: async (chatroom_id, page) => {
        const response = await fetchGet({
            url: `/api/private/chat/list${getQueryString({chatroom_id, page})}`
        });

        return response.item;
    },
    deleteChat: async (chat_id) => {
        const response = await fetchDelete({
            url: `/api/private/chat`,
            body: {chat_id}
        });

        return response;
    }
}