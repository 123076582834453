import {fetchDelete, fetchGet, fetchPost, fetchPut, getQueryString} from "../fetchLib";

export default {
    getNoticeList: async (request) => {
        const response = await fetchGet({
            url: `/api/public/notice/list${getQueryString(request)}`,
        });

        return response;
    },
    getNotice: async (notice_id) => {
        const response = await fetchGet({
            url: `/api/private/notice${getQueryString({notice_id})}`
        });

        return response.item;
    },
    postNotice: async (request) => {
        const response = await fetchPost({
            url: `/api/private/notice`,
            body: request,
        });

        return response.item;
    },
    updateNotice: async (request) => {
        const response = await fetchPut({
            url: `/api/private/notice`,
            body: request,
        });

        return response;
    },
    deleteNotice: async (notice_id) => {
        const response = await fetchDelete({
            url: `/api/private/notice`,
            body: {notice_id},
        });

        return response;
    }
}