import {css} from "@emotion/react";
import colors from "../../../styles/Colors";

const WeekdayButton = ({checked, onClick, weekday}) => {
    const weekdayName = {
        64: '월',
        32: '화',
        16: '수',
        8: '목',
        4: '금',
        2: '토',
        1: '일',
    }

    const onButtonClick = () => {
        const addWeekday = weekday * (checked ? -1 : 1);
        onClick(addWeekday);
    }

    return (
        <button css={toggleButtonStyle(checked)} onClick={onButtonClick}>
            {weekdayName[weekday]}
        </button>
    );
}

const toggleButtonStyle = (checked) => css`
  width: 34px;
  height: 34px;
  background-color: ${checked ? colors.primary : colors.font.dim200};
  border-radius: 100%;
  color: ${checked ? colors.font.default : colors.background.light};
  font-size: 16px;
`;

export default WeekdayButton;