import {css} from "@emotion/react";
import colors from "../styles/Colors";

const CardView = ({children, title, ...props}) => {
    return (
        <div css={cardStyle} {...props}>
            {title && (<span css={titleStyle}>{title}</span>)}
            {children}
        </div>
    );
}

const cardStyle = css`
  display: flex;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  flex-direction: column;
  border: 1px solid ${colors.font.dim100};
  box-shadow: 0 2px 4px ${colors.font.dim100};
`;

const titleStyle = css`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export default CardView;