import {Route, Routes} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import {createTheme, ThemeProvider} from "@mui/material";
import colors from "/src/styles/Colors";
import {red} from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primary,
            dark:colors.primary_hover,
        },
        secondary: {
            main: colors.secondary,
        },
        warning: {
            main: red[500]
        },
    }
})

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route path='/' element={<LoginPage/>}/>
                <Route path='/*' element={<MainPage/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
