import MainLayout from "../../components/Layout/MainLayout";
import MaterialBoard from "../../components/MaterialBoard";
import {useEffect, useState} from "react";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import {useNavigate} from "react-router-dom";
import api from "../../lib/api";

const ChatRoomListPage = () => {
    const navigate = useNavigate();
    const [chatRoomList, setChatRoomList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const columns = [
        {field: 'chatroom_id', headerName: 'ID', type: 'number', width: 64},
        {field: 'location_name', headerName: '지역명'},
        {field: 'name', headerName: '매장명', minWidth: 120, flex: 1},
        {field: 'chat_count', headerName: '채팅 수'},
        {field: 'id', headerName: '매장 ID'},
    ];

    useEffect(() => {
        setIsLoading(true);
        api.getChatRoomList().then((response) => {
            setChatRoomList(response)
        }).finally(() => setIsLoading(false));
    }, []);

    const onRowClickHandler = ({row}) => {
        navigate(`${row.chatroom_id}`, {state: row});
    }

    return (
        <MainLayout title={'채팅 관리'}>
            <MaterialBoard columns={columns} rows={chatRoomList} onRowClick={onRowClickHandler}/>
            <LoadingDialog open={isLoading}/>
        </MainLayout>
    );
}

export default ChatRoomListPage;