import {fetchDelete, fetchGet, fetchPut, getQueryString} from "../fetchLib";
import crypto from "crypto";

const encryptPassword = (password) => crypto.createHash('sha256').update(password).digest('base64');

export default {
    getUser: async (user_id) => {
        const response = await fetchGet({
            url: `/api/public/user${getQueryString({user_id})}`
        });

        return response.item;
    },
    getUserList: async (request) => {
        const response = await fetchGet({
            url: `/api/public/user/list${getQueryString(request)}`
        });

        return response;
    },
    updateUser: async (request) => {
        const response = await fetchPut({
            url: `/api/private/admin/user`,
            body: request,
        });

        return response.item;
    },
    updateUserPassword: async (request) => {
        await fetchPut({
            url: `/api/private/admin/password`,
            body: {
                ...request,
                password: encryptPassword(request.password),
            },
        });
    },
    deleteUser: async (target_user_id) => {
        const response = await fetchDelete({
            url: `/api/private/admin/user`,
            body: {target_user_id}
        });

        return response;
    }
}