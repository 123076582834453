import CardView from "../CardView";
import BarBasicInformationContainer from "./BarBasicInformationContainer";
import BarContactInformationContainer from "./BarContactInformationContainer";

const BarInformationContainer = ({barImageState}) => {
    return (
        <CardView>
            <BarBasicInformationContainer barImageState={barImageState}/>
            <BarContactInformationContainer/>
        </CardView>
    );
}

export default BarInformationContainer;