import {createReducer} from "@reduxjs/toolkit";
import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import api from "../lib/api";

const GET_LOCATION = 'location/GET_LOCATION';
const PUT_LOCATION = 'location/PUT_LOCATION';

const initialState = {
    data: {
        location: []
    },
    ui: {
        isLoading: false,
    },
};

const getLocationActions = makeAsyncActionCreator(GET_LOCATION);
const putLocationActions = makeAsyncActionCreator(PUT_LOCATION);

const getLocation = () => async (dispatch, state) => {
    dispatch(getLocationActions.INDEX());

    try {
        let locationList = state().location.data.location;

        if (locationList.length === 0) {
            locationList = await api.getBarLocationList();
        }

        dispatch(getLocationActions.SUCCESS(locationList));
    } catch (e) {
        dispatch(getLocationActions.FAIL({error: e}));
    }
}

const putLocation = (request) => async dispatch => {
    dispatch(putLocationActions.INDEX());

    try {
        const response = await api.updateLocation(request);
        dispatch(putLocationActions.SUCCESS(response));
    } catch (e) {
        dispatch(putLocationActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_LOCATION, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.location = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(PUT_LOCATION, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.location = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
});

export const LocationActions = {
    getLocation,
    putLocation,
}

export default reducer;