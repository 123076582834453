import CardView from "../CardView";
import MaterialButton from "../MaterialButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import MenuDialog from "../Dialog/MenuDialog";

const BarMenuContainer = () => {
    const menu = useSelector((state) => state.bar.ui.bar?.menu);

    const [openMenuDialog, setOpenMenuDialog] = useState(false);

    const onButtonClickHandler = () => {
        setOpenMenuDialog(true);
    }

    const onMenuDialogClose = () => {
        setOpenMenuDialog(false);
    }

    return (
        <CardView title={'메뉴 관리'}>
            {menu?.map(e => e && (
                <span key={e}>{e}</span>
            ))}
            <MaterialButton css={{marginTop: 12}} onClick={onButtonClickHandler}>메뉴 설정</MaterialButton>

            <MenuDialog open={openMenuDialog} onClose={onMenuDialogClose}/>
        </CardView>
    );
}

export default BarMenuContainer;