import {fetchPost, fetchPut} from "../fetchLib";

export default {
    uploadImage: async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        const response = await fetchPost({
            url: `/api/private/image`,
            body: formData
        });

        return response.item;
    },
    updateTopImage: async (file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const response = await fetchPut({
            url: `/api/private/image/top-image`,
            body: formData
        });

        return response;
    }
}