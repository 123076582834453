import MaterialBoard from "../MaterialBoard";
import {useSelector} from "react-redux";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const NoticeBoard = () => {
    const navigate = useNavigate();
    const {notice, count, isLoading} = useSelector((state) => ({
        notice: state.notice.data.notice,
        count: state.notice.data.count,
        isLoading: state.notice.ui.isLoading,
    }));

    const dateFormatter = ({value}) => moment(value).format("YYYY.MM.DD");

    const columns = [
        {field: 'id', headerName: 'ID', type: 'number', width: 64},
        {field: 'title', headerName: '제목', flex: 1},
        {field: 'created_time', headerName: '작성일', width: 100, valueFormatter: dateFormatter,}
    ];

    const onNoticeClickHandler = ({row}) => {
        navigate(`${row.id}`, {state: row});
    }

    return (
        <MaterialBoard columns={columns} rows={notice} count={count} isLoading={isLoading} onRowClick={onNoticeClickHandler}/>
    );
}

export default NoticeBoard;