import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {UserActions} from "../../store/User";
import MainLayout from "../../components/Layout/MainLayout";
import UserSearchContainer from "../../components/user/UserSearchContainer";
import UserBoard from "../../components/user/UserBoard";
import {useSearchParams} from "react-router-dom";

const UserListPage = () => {
    const dispatch = useDispatch();
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const query = Object.fromEntries(searchParams.entries());
        dispatch(UserActions.getUserList(query));
    }, [dispatch, searchParams]);

    return (
        <MainLayout title='회원 관리'>
            <UserSearchContainer/>
            <UserBoard/>
        </MainLayout>
    );
}

export default UserListPage;