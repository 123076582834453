import {Dialog} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MaterialTextField from "../MaterialTextField";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import MaterialButton from "../MaterialButton";
import {BarActions} from "../../store/Bar";

const MenuDialog = ({open, onClose, ...props}) => {
    const dispatch = useDispatch();
    const menu = useSelector((state) => state.bar.ui.bar?.menu || []);

    const [newMenuList, setNewMenuList] = useState([...menu]);
    const newMenuRef = useRef();

    useEffect(() => {
        if (open) {
            setNewMenuList(menu);
        }
    }, [open]);

    const onMenuChanged = (index, target) => {
        newMenuList[index] = target.value;
    }

    const addMenuHandler = () => {
        const newMenu = newMenuRef.current.value;

        if (newMenu && !newMenuList.includes(newMenu)) {
            setNewMenuList((prevMenuList) => {
                const menuList = [...prevMenuList, newMenu];
                newMenuRef.current.value = '';
                return menuList
            })
        }
    }

    const deleteMenuHandler = (index) => {
        setNewMenuList((prevMenuList) => {
            const menuList = [...prevMenuList];
            menuList[index] = undefined;
            return menuList;
        });
    }

    const onSaveHandler = async () => {
        const response = await dispatch(BarActions.putMenu(newMenuList));
        setNewMenuList(response);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <div css={rootStyle}>
                <span css={menuLabelStyle}>메뉴 설정</span>
                {newMenuList.map((e, index) => e && (
                    <div key={e} css={menuStyle}>
                        <MaterialTextField css={menuNameStyle} key={e} defaultValue={e}
                                           onChange={({target}) => onMenuChanged(index, target)}/>
                        <MaterialButton css={deleteMenuButtonStyle} color='warning'
                                        onClick={() => deleteMenuHandler(index)}>-</MaterialButton>
                    </div>
                ))}
                <div css={menuStyle}>
                    <MaterialTextField css={menuNameStyle} ref={newMenuRef} placeholder={'메뉴 추가'}/>
                    <MaterialButton css={deleteMenuButtonStyle} onClick={addMenuHandler}>+</MaterialButton>
                </div>
                <div css={buttonsStyle}>
                    <MaterialButton onClick={onClose} color='warning'>취소</MaterialButton>
                    <MaterialButton onClick={onSaveHandler} css={{marginLeft: 4}}>저장</MaterialButton>
                </div>
            </div>
        </Dialog>
    );
}

const rootStyle = css`
  padding: 12px;
`;

const menuLabelStyle = css`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

const menuStyle = css`
  display: flex;
  margin-top: 4px;
`;

const menuNameStyle = css`
  flex-grow: 1;
`;

const deleteMenuButtonStyle = css`
  margin-left: 4px;
`;

const buttonsStyle = css`
  margin-top: 8px;
  text-align: end;
`;

export default MenuDialog;