import colors from "../styles/Colors";
import {css} from "@emotion/react";
import {InputAdornment, TextField} from "@mui/material";
import {forwardRef} from "react";

const textFieldStyle = (multiline) => css`
  background-color: ${colors.background.light};
  border-radius: ${multiline ? '20px' : '200px'};

  & .MuiInputLabel-root {
    &.MuiInputLabel-shrink {
      color: ${colors.primary};
    }
  }

  & .MuiOutlinedInput-input {
    color: ${colors.font.default};
  }

  & .MuiOutlinedInput-root {
    &:hover {
      border-color: ${colors.primary_hover};
      border-radius: ${multiline ? '20px' : '200px'};
    }

    &.Mui-focused fieldset {
      border-color: ${colors.primary};
      border-radius: ${multiline ? '20px' : '200px'};
    }
  }
`

const MaterialTextFieldBlack = forwardRef(({label, icon, ...props}, ref) => {
    const inputProps = {
        startAdornment: (
            <InputAdornment position='start'>
                {icon}
            </InputAdornment>
        ),
    };

    return (
        <TextField
            css={textFieldStyle(props.multiline)}
            label={label}
            InputProps={inputProps}
            inputRef={ref}
            {...props}
        />
    );
});

export default MaterialTextFieldBlack;