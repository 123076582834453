import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {DataGrid} from "@mui/x-data-grid";
import {useSearchParams} from "react-router-dom";
const MaterialBoard = ({rows, count, isLoading, onCheckParams, ...props}) => {
    const [searchParams, setSearchParams] = useSearchParams({
        page: 0,
        limit: 10,
    });
    const page = parseInt(searchParams.get('page'));
    const limit = parseInt(searchParams.get('limit'));

    const onPageChange = (newPage) => {
        const params = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...params,
            page: newPage,
        });
    };

    const onPageSizeChange = (newPageSize) => {
        const params = Object.fromEntries(searchParams.entries());
        setSearchParams({
            ...params,
            limit: newPageSize,
        });
    }

    return (
        <DataGrid
            css={boardStyle}
            loading={isLoading}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            rows={rows}
            rowCount={count}
            page={page}
            pageSize={limit}
            paginationMode={'server'}
            rowsPerPageOptions={[10, 20, 30]}
            {...props}/>
    );
}

const boardStyle = css`
  height: 70vh;
  background-color: white;
  border: 1px solid ${colors.font.dim100};
  box-shadow: 0 2px 4px ${colors.font.dim100};
`;

export default MaterialBoard;