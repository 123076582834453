import CardView from "../../components/CardView";
import MaterialButton from "../../components/MaterialButton";
import {css} from "@emotion/react";
import colors from "../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {AppConfigsActions} from "../../store/AppConfigs";

const AppTopImage = () => {
    const dispatch = useDispatch();
    const topImage = useSelector((state) => state.appConfigs.data.topImage);

    const onFileUpdated = ({target}) => {
        const file = target.files[0];
        dispatch(AppConfigsActions.updateTopImage(file));
    }

    return (
        <CardView title={'상단 이미지'}>
            <div css={topImageStyle(topImage)}/>
            <MaterialButton component='label'>
                업로드
                <input type='file' accept='image/*' onChange={onFileUpdated} hidden/>
            </MaterialButton>
        </CardView>
    );
}

const topImageStyle = (topImage) => css`
  background: url(${process.env.REACT_APP_TIPXY_S3_URL}${topImage}) center center/cover;
  background-color: ${colors.font.dim100};
  width: 300px;
  height: 140px;
  text-align: center;
  border-radius: 4px;
  align-self: center;
  margin: 0 8px 8px;
`;

export default AppTopImage;