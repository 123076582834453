import CardView from "../CardView";
import MaterialTextField from "../MaterialTextField";
import MaterialButton from "../MaterialButton";
import {LocationActions} from "../../store/Location";
import {useDispatch, useSelector} from "react-redux";
import {css} from "@emotion/react";
import {useEffect} from "react";

const LocationSetting = () => {
    const dispatch = useDispatch();
    const locationList = useSelector(state => state.location.data.location);

    useEffect(() => {
        if (locationList.length === 0) {
            dispatch(LocationActions.getLocation());
        }
    }, [])

    const updateLocationHandler = ({target}) => {
        const locationId = parseInt(target.value);

        const locationName = document.getElementById(`locationName${locationId}`).value;
        const locationPriority = document.getElementById(`locationPriority${locationId}`).value;

        const request = {
            location_id: locationId,
            name: locationName,
            priority: locationPriority === '' ? undefined : locationPriority,
        };

        dispatch(LocationActions.putLocation(request));
    }

    return (
        <CardView title={'지역 목록 설정'}>
            {locationList.map((e) =>
                <div key={e.id} css={locationInputStyle}>
                    <span css={locationIdStyle}>{e.id}</span>
                    <MaterialTextField id={`locationName${e.id}`} css={locationNameInputStyle}
                                       defaultValue={e.name}/>
                    <MaterialTextField id={`locationPriority${e.id}`} css={locationPriorityInputStyle}
                                       type={'number'} defaultValue={e.priority}/>
                    <MaterialButton value={e.id} onClick={updateLocationHandler}>적용</MaterialButton>
                </div>
            )}
        </CardView>
    );
}


const locationIdStyle = css`
  text-align: end;
  width: 30px;
  align-self: center;
`;

const locationInputStyle = css`
  display: flex;
  margin-bottom: 4px;
`;

const locationNameInputStyle = css`
  flex-grow: 1;
  margin-left: 4px;
`;

const locationPriorityInputStyle = css`
  width: 60px;
  margin: 0 4px;
`;

export default LocationSetting;