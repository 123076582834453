import MaterialBoard from "../MaterialBoard";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Avatar} from "@mui/material";

const BarBoard = () => {
    const navigate = useNavigate();
    const {bar, count, isLoading} = useSelector((state)=> ({
        bar: state.bar.data.noImage,
        count: state.bar.data.count,
        isLoading: state.bar.ui.isLoading,
    }));

    const clickRowHandler = (row) => {
        navigate(`/bar/${row.id}`, {state: row.row});
    }

    const columns = [
        {field: 'id', headerName: 'ID', type: 'number', width: 64},
        {field: 'location_name', headerName: '지역', type: 'string'},
        {field: 'name', headerName: '매장명', type: 'string', minWidth: 140, flex: 1},
        {field: 'owner_id', headerName: '사장님 유무', type: 'boolean'}
    ];

    return (
        <MaterialBoard columns={columns} rows={bar} count={count} isLoading={isLoading} onRowClick={clickRowHandler}/>
    );
}

export default BarBoard;