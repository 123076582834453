import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {ApplyActions} from "../../store/Apply";
import MainLayout from "../../components/Layout/MainLayout";
import ApplyBoard from "../../components/apply/ApplyBoard";

const ApplyListPage = () => {
    const dispatch = useDispatch();
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const query = Object.fromEntries(searchParams.entries());
        dispatch(ApplyActions.getApplyList(query));
    }, [dispatch, searchParams]);

    return (
        <MainLayout title='제보 관리'>
            <ApplyBoard/>
        </MainLayout>
    );
}

export default ApplyListPage;