import CardView from "./CardView";
import MaterialButton from "./MaterialButton";
import {css} from "@emotion/react";
import {MOBILE_WIDTH} from "../Constants";
import {useSearchParams} from "react-router-dom";

const cardStyle = css`
    margin-bottom: 12px;
`;

const searchFormStyle = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MOBILE_WIDTH}) {
    flex-direction: row;
  }
`;

const searchButtonStyle = css`
  margin: 8px 0;
`;

const SearchContainer = ({children, onSearch, ...props}) => {
    const [_, setSearchParams] = useSearchParams();

    const onReset = () => {
        setSearchParams({
            page: 0,
            limit: 10,
        });
    };

    return (
        <CardView css={cardStyle} {...props}>
            <div css={searchFormStyle}>
                {children}
            </div>
            <MaterialButton css={searchButtonStyle} onClick={onSearch}>검색</MaterialButton>
            <MaterialButton color='warning' onClick={onReset}>초기화</MaterialButton>
        </CardView>
    );
}

export default SearchContainer;