import MaterialButton from "../MaterialButton";
import React, {useState} from "react";
import {css} from "@emotion/react";
import DeleteDialog from "../Dialog/DeleteDialog";
import {useDispatch, useSelector} from "react-redux";
import {BarActions} from "../../store/Bar";
import {useNavigate} from "react-router-dom";

const BarMenu = ({barImageState}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bar = useSelector((state) => state.bar.ui.bar);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [barImage, setBarImage] = barImageState;

    const onSave = async () => {
        const request = {
            ...bar,
            bar_id: bar.id,
        };

        await dispatch(BarActions.putBar(request, barImage));
        setBarImage();
    }

    const onDelete = async () => {
        await dispatch(BarActions.deleteBar(bar.id));
        navigate('/bar', {replace: true});
    }

    return (
        <div>
            <MaterialButton css={buttonsStyle} onClick={() => setOpenDeleteDialog(true)}
                            color='warning'>삭제</MaterialButton>
            <MaterialButton css={buttonsStyle} onClick={onSave}>저장</MaterialButton>

            <DeleteDialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}
                          closeDeleteDialogHandler={() => setOpenDeleteDialog(false)}
                          deleteHandler={onDelete}/>
        </div>
    );
}

const buttonsStyle = css`
  margin-left: 4px;
`;

export default BarMenu;