import {createAction} from "@reduxjs/toolkit";

const getActionsName = (action) => ({
    INDEX: `${action}`,
    SUCCESS: `${action}_SUCCESS`,
    FAIL: `${action}_FAIL`,
});

export const makeAsyncActionCreator = (action) => {
    const actions = getActionsName(action);

    return {
        INDEX: createAction(actions.INDEX),
        SUCCESS: createAction(actions.SUCCESS),
        FAIL: createAction(actions.FAIL),
    };
}

export const makeAsyncActionsHandler = (action, {onRequest, onSuccess, onFail}) => {
    const actions = getActionsName(action);

    return {
        [actions.INDEX]: (state, action) => onRequest(state, action),
        [actions.SUCCESS]: (state, action) => onSuccess(state, action),
        [actions.FAIL]: (state, action) => onFail(state, action),
    };
}