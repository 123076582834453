import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import {createAction, createReducer} from "@reduxjs/toolkit";
import api from "../lib/api";

const GET_USER_LIST = 'user/GET_USER_LIST';
const GET_USER = 'user/GET_USER';
const PUT_USER = 'user/PUT_USER';
const PUT_USER_BAR = 'user/PUT_USER_BAR';
const PUT_PASSWORD = 'user/PUT_PASSWORD';
const DELETE_USER = 'user/DELETE_USER';
const SET_USER = 'user/SET_USER';
const CLEAR_ERROR = 'user/CLEAR_ERROR';

const initialState = {
    data: {
        user: [],
        count: 0,
    },
    ui: {
        isLoading: false,
        user: undefined,
    },
    error: {
        password: '',
    }
};

const getUserListActions = makeAsyncActionCreator(GET_USER_LIST);
const getUserActions = makeAsyncActionCreator(GET_USER);
const putUserActions = makeAsyncActionCreator(PUT_USER);
const putUserBarActions = makeAsyncActionCreator(PUT_USER_BAR);
const putPasswordActions = makeAsyncActionCreator(PUT_PASSWORD);
const deleteUserActions = makeAsyncActionCreator(DELETE_USER);

const getUserList = (query) => async dispatch => {
    dispatch(getUserListActions.INDEX());

    try {
        const response = await api.getUserList(query);
        dispatch(getUserListActions.SUCCESS(response));
    } catch (e) {
        dispatch(getUserListActions.FAIL({error: e}));
    }
}

const getUser = (id) => async dispatch => {
    dispatch(getUserActions.INDEX());

    try {
        const response = await api.getUser(id);
        dispatch(getUserActions.SUCCESS(response));
    } catch (e) {
        dispatch(getUserActions.FAIL({error: e}));
    }
}

const putUser = (request) => async dispatch => {
    dispatch(putUserActions.INDEX());

    try {
        const req = {
            ...request,
            target_user_id: request.id,
        };

        const response = await api.updateUser(req);
        dispatch(putUserActions.SUCCESS(response));
    } catch (e) {
        dispatch(putUserActions.FAIL({error: e}));
    }
}

const putUserBar = (newBarIdList) => async (dispatch, state) => {
    dispatch(putUserBarActions.INDEX());

    try {
        const originalBarIdList = state().user.ui.user?.bar_id ?? [];
        await Promise.all(newBarIdList.map((e, index) => {
            if (!e && !originalBarIdList[index]) {
                return;
            }

            if (e === originalBarIdList[index]) {
                return;
            }

            return api.updateBarOwner({
                original_bar_id: originalBarIdList[index],
                bar_id: e,
                owner_id: state().user.ui.user?.id
            });
        }));

        const remainedBarIdList = newBarIdList.filter((e) => e);
        return await dispatch(putUserBarActions.SUCCESS(remainedBarIdList));
    } catch (e) {
        dispatch(putUserBarActions.FAIL({error: e}));
    }
}

const putPassword = (request) => async dispatch => {
    try {
        dispatch(putPasswordActions.INDEX(request));
        delete request.confirm;
        await api.updateUserPassword(request);
        dispatch(putPasswordActions.SUCCESS());
    } catch (e) {
        dispatch(putPasswordActions.FAIL({error: e}));
        return e;
    }
}

const deleteUser = (id) => async dispatch => {
    dispatch(deleteUserActions.INDEX());

    try {
        await api.deleteUser(id);
        dispatch(deleteUserActions.SUCCESS());
    } catch (e) {
        dispatch(deleteUserActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_USER_LIST, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.user = action.payload.item.user;
            state.data.count = action.payload.item.count;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(GET_USER, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.user = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(PUT_USER, {
        onRequest: (state, action) => {
            state.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(PUT_USER_BAR, {
        onRequest: (state, action) => {
            state.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.user.bar_id = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(PUT_PASSWORD, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;

            const {password, confirm} = action.payload;

            const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?/]{8,}$/
            if (!regex.test(password)) {
                throw Error('비밀번호는 8자 이상, 영문, 숫자가 포함되어야 합니다');
            }

            if (password !== confirm) {
                throw Error('비밀번호가 일치하지 않습니다');
            }
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            state.error.password = action.payload.error.message;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(DELETE_USER, {
        onRequest: (state, action) => {
            state.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    [SET_USER]: (state, action) => {
        state.ui.user = action.payload;
    },
    [CLEAR_ERROR]: (state, action) => {
        state.error = {};
    }
});

export const UserActions = {
    getUserList,
    getUser,
    putUser,
    putPassword,
    putUserBar,
    deleteUser,
    setUser: createAction(SET_USER),
    clearError: createAction(CLEAR_ERROR),
};

export default reducer;