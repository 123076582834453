import {TextField} from "@mui/material";
import colors from "../styles/Colors";
import {css} from "@emotion/react";
import {forwardRef} from "react";

const textFieldStyle = css`
  color: ${colors.font.default};
  
  & .MuiInputLabel-root {
    &.MuiInputLabel-shrink {
      color: ${colors.primary};
    }
  }
  
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${colors.primary};
    }
  }
`

const MaterialTextField = forwardRef(({label, onChange, ...props}, ref) => {
    return (
        <TextField
            css={textFieldStyle}
            label={label}
            onChange={onChange}
            inputRef={ref}
            {...props}
        />
    );
});

export default MaterialTextField;