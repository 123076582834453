import MaterialTextField from "../MaterialTextField";
import MaterialButton from "../MaterialButton";
import {useRef} from "react";
import {css} from "@emotion/react";
import {useDispatch, useSelector} from "react-redux";
import {BarActions} from "../../store/Bar";
import {useNavigate} from "react-router-dom";

const BarContactInformationContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const bar = useSelector((state) => state.bar.ui.bar);

    const ownerIdRef = useRef(bar?.owner_id);
    const contactRef = useRef(bar?.contact);
    const instagramRef = useRef(bar?.instagram);

    const updateBar = () => {
        const newBar = {
            ...bar,
            owner_id: ownerIdRef.current.value,
            contact: contactRef.current.value,
            instagram: instagramRef.current.value,
        };

        dispatch(BarActions.setBar(newBar));
    };

    const openInstagramPage = () => {
        window.open(`${process.env.REACT_APP_TIPXY_API_URL}/api/public/instagram`);
        window.addEventListener('focusin', (event) => {
            navigate(0);
        });
    }

    return (
        <div>
            <div css={contactContainerStyle}>
                <MaterialTextField label={'사장님 ID'} css={ownerIdInputStyle} ref={ownerIdRef}
                                   value={bar?.owner_id || ''} type='number' onChange={updateBar}/>
                <MaterialTextField label='연락처' css={informationInputStyle} ref={contactRef}
                                   value={bar?.contact || ''} onChange={updateBar} fullWidth/>
                <MaterialTextField label='인스타그램' css={informationInputStyle} ref={instagramRef}
                                   value={bar?.instagram || ''} onChange={updateBar} fullWidth/>
            </div>
            {bar?.instagram && (<MaterialButton css={instagramButtonStyle} onClick={openInstagramPage}
                                                disabled={!!bar?.instagram_access_token} fullWidth>
                {bar?.instagram_access_token ? '인스타그램 연동 완료' : '인스타그램 연동'}
            </MaterialButton>)}
        </div>
    );
}

const contactContainerStyle = css`
  display: flex;
  justify-content: end;
  align-content: space-between;
`;

const informationInputStyle = css`
  margin-left: 4px;
`;

const ownerIdInputStyle = css`
  ${informationInputStyle};
  margin-left: 0;
  min-width: 112px;
`;

const instagramButtonStyle = css`
  margin-top: 8px;
`;

export default BarContactInformationContainer;