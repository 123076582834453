import {css} from "@emotion/react";
import MaterialButton from "/src/components/MaterialButton";

const buttonStyle = css`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 700;
`;

const TipxyButton = ({warning, ...props}) => {
    return (
        <MaterialButton
            css={buttonStyle}
            {...props}/>
    );
}

export default TipxyButton;