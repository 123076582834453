import {Button} from "@mui/material";

const MaterialButton = ({onClick, ...props}) => {
    return (
        <Button
            variant='contained'
            onClick={onClick}
            {...props}/>
    );
}

export default MaterialButton;