import LogoIcon from "../icons/LogoIcon";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import React, {useEffect, useState} from "react";
import TipxyButton from "../components/TipxyButton";
import {useNavigate} from "react-router-dom";
import MaterialTextFieldBlack from "../components/TipxyTextFieldBlack";
import LoadingDialog from "../components/Dialog/LoadingDialog";
import {STORAGE_USER} from "../Constants";
import api from "../lib/api";

const rootStyle = css`
  height: 100vh;
  background-color: ${colors.background.default};
  color: ${colors.font.default};
`

const containerStyle = css`
  margin: auto;
  text-align: center;
  max-width: 360px;
  padding-top: 140px;
`

const textFieldStyle = css`
  margin-bottom: 20px;
  width: 100%;
`

const errorMessageStyle = css`
  display: block;
  margin-top: 24px;
  color: red;
`

const LoginPage = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const emailRef = React.createRef();
    const passwordRef = React.createRef();

    useEffect(() => {
        const userId = localStorage.getItem(STORAGE_USER.USER_ID);

        if (userId) {
            navigate('/configs');
        }
    }, [])

    const clickLoginButtonHandler = async () => {
        setIsLoading(true);
        setErrorMessage(null);
        await api.signInWithEmail(emailRef.current.value, passwordRef.current.value)
            .then(response => {
                if (response.type !== 2) setErrorMessage('관리자 권한이 없습니다.')
                else {
                    localStorage.setItem(STORAGE_USER.USER_ID, response.id);
                    localStorage.setItem(STORAGE_USER.ACCESS_TOKEN, response.access_token);
                    localStorage.setItem(STORAGE_USER.REFRESH_TOKEN, response.refresh_token);
                    navigate('/configs');
                }
            })
            .catch(e => setErrorMessage(e.response.data.message));
        setIsLoading(false);
    }

    return (
        <div css={rootStyle}>
            <div css={containerStyle}>
                <LogoIcon size={1.4}/>
                <p>관리자 페이지</p>
                <MaterialTextFieldBlack css={textFieldStyle} ref={emailRef} type="email" placeholder="이메일을 입력해 주세요."/>
                <MaterialTextFieldBlack css={textFieldStyle} ref={passwordRef} type="password"
                                     placeholder="비밀번호를 입력해 주세요."/>
                <TipxyButton onClick={clickLoginButtonHandler}>로그인</TipxyButton>
                <span css={errorMessageStyle}>{errorMessage}</span>
            </div>

            <LoadingDialog open={isLoading}/>
        </div>
    );
}

export default LoginPage;