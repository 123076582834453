import {combineReducers, configureStore} from "@reduxjs/toolkit";
import Location from "./Location";
import AppConfigs from "./AppConfigs";
import Notice from "./Notice";
import User from "./User";
import Image from "./Image";
import Bar from "./Bar";
import Apply from "./Apply";

const reducer = combineReducers({
    appConfigs: AppConfigs,
    location: Location,
    notice: Notice,
    user: User,
    bar: Bar,
    apply: Apply,
    image: Image,
});

export default configureStore({reducer});