import MaterialBoard from "../MaterialBoard";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Avatar} from "@mui/material";

const BarBoard = () => {
    const navigate = useNavigate();
    const {bar, count, isLoading} = useSelector((state)=> ({
        bar: state.bar.data.bar,
        count: state.bar.data.count,
        isLoading: state.bar.ui.isLoading,
    }));

    const clickRowHandler = (row) => {
        navigate(`${row.id}`, {state: row.row});
    }

    const renderImageCell = (params) => params.value ? (
        <Avatar src={process.env.REACT_APP_TIPXY_S3_URL + params.value}/>
    ) : undefined;

    const columns = [
        {field: 'id', headerName: 'ID', type: 'number', width: 64},
        {field: 'filename', headerName: '이미지', type: 'string', width: 56, renderCell: renderImageCell},
        {field: 'location_name', headerName: '지역', type: 'string'},
        {field: 'name', headerName: '매장명', type: 'string', minWidth: 140, flex: 1},
        {field: 'owner_id', headerName: '사장님 유무', type: 'boolean'}
    ];

    return (
        <MaterialBoard columns={columns} rows={bar} count={count} isLoading={isLoading} onRowClick={clickRowHandler}/>
    );
}

export default BarBoard;