const MOBILE_WIDTH = '550px';
const MIN_WIDTH = '320px';

const STORAGE_USER = {
    USER_ID: 'user_id',
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
};

module.exports = {
    MOBILE_WIDTH,
    MIN_WIDTH,
    STORAGE_USER,
};