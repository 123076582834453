import CardView from "../CardView";
import MaterialButton from "../MaterialButton";
import MaterialTextField from "../MaterialTextField";
import MaterialSelect from "../MaterialSelect";
import {MenuItem} from "@mui/material";
import React, {useRef, useState} from "react";
import {css} from "@emotion/react";
import colors from "../../styles/Colors";
import {useDispatch, useSelector} from "react-redux";
import {UserActions} from "../../store/User";

const UserInfromationContainer = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.ui.user);

    const [profileImage, setProfileImage] = useState();

    const nicknameRef = useRef();
    const emailRef = useRef();

    const userTypes = [{id: 0, name: '일반 회원'}, {id: 1, name: '사장님'}, {id: 2, name: '관리자'}];

    const getFileUrl = () => {
        if (profileImage) return URL.createObjectURL(profileImage)
        if (!user?.is_anonymous && user?.profile) return process.env.REACT_APP_TIPXY_S3_URL + user?.profile;
        return undefined;
    }

    const onFileUpdated = ({target}) => {
        setProfileImage(target.files[0]);
    }

    const onUpdateTextField = () => {
        const newUser = {
            ...user,
            nickname: nicknameRef.current.value,
            email: emailRef.current.value,
        };

        dispatch(UserActions.setUser(newUser));
    }

    const setType = ({target}) => {
        const newUser = {
            ...user,
            type: target.value,
        };

        dispatch(UserActions.setUser(newUser));
    }

    return (
        <CardView css={informationCardStyle}>
            <MaterialButton css={thumbnailImageStyle(user?.id, getFileUrl())} variant='contained' component='label'>
                <input type='file' accept='image/*' onChange={onFileUpdated} hidden/>
                {(user?.is_anonymous && !getFileUrl()) && <span css={profileEmojiStyle}>{user?.profile}</span>}
            </MaterialButton>
            <div css={informationInputStyle}>
                <MaterialTextField label='닉네임' css={textFieldStyle} ref={nicknameRef}
                                   value={user?.nickname || ''} onChange={onUpdateTextField}/>
                <MaterialTextField label='이메일' css={textFieldStyle} ref={emailRef}
                                   value={user?.email || ''} onChange={onUpdateTextField}/>
                <div css={userTypeContainerStyle}>
                    <MaterialSelect css={userTypeInputStyle} value={user?.type || 0} onChange={setType}>
                        {userTypes.map((e) => (
                            <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                        ))}
                    </MaterialSelect>
                </div>
            </div>
        </CardView>
    );
}

const informationCardStyle = css`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
`;

const thumbnailImageStyle = (id, profileImage) => css`
  background: url(${profileImage}) center center/cover;
  background-color: ${colors.profileBgColor[id % colors.profileBgColor.length]};
  width: 200px;

  &:hover {
    background: url(${profileImage}) center center/cover;
    opacity: 0.8;
  }
`;

const informationInputStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 4px;
`;

const profileEmojiStyle = css`
  font-size: 100px;
`;

const textFieldStyle = css`
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`;

const userTypeContainerStyle = css`
  display: flex;
`;

const userTypeInputStyle = css`
  ${textFieldStyle};
  width: 100%;
`;

export default UserInfromationContainer;