import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import {createAction, createReducer} from "@reduxjs/toolkit";
import api from "../lib/api";

const GET_NOTICE_LIST = 'notice/GET_NOTICE_LIST';
const GET_NOTICE = 'notice/GET_NOTICE';
const SEND_NOTICE = 'notice/SEND_NOTICE';
const DELETE_NOTICE = 'notice/DELETE_NOTICE';
const SET_NOTICE = 'notice/SET_NOTICE';
const CLEAR_NOTICE = 'notice/CLEAR_NOTICE';

const initialState = {
    data: {
        notice: [],
        count: 0,
    },
    ui: {
        isLoading: false,
        notice: undefined,
    },
};

const getNoticeListActions = makeAsyncActionCreator(GET_NOTICE_LIST);
const getNoticeActions = makeAsyncActionCreator(GET_NOTICE);
const sendNoticeActions = makeAsyncActionCreator(SEND_NOTICE);
const deleteNoticeActions = makeAsyncActionCreator(DELETE_NOTICE);

const getNoticeList = (query) => async dispatch => {
    dispatch(getNoticeListActions.INDEX());

    try {
        const response = await api.getNoticeList(query);
        dispatch(getNoticeListActions.SUCCESS(response));
    } catch (e) {
        dispatch(getNoticeListActions.FAIL({error: e}));
    }
}

const getNotice = (id) => async dispatch => {
    dispatch(getNoticeActions.INDEX());

    try {
        const response = await api.getNotice(id);
        dispatch(getNoticeActions.SUCCESS(response));
    } catch (e) {
        dispatch(getNoticeActions.FAIL({error: e}));
    }
}

const sendNotice = (request) => async dispatch => {
    dispatch(sendNoticeActions.INDEX());

    try {
        let response;

        if (!request.notice_id) {
            response = await api.postNotice(request);
        } else {
            response = await api.updateNotice(request);
        }

        dispatch(sendNoticeActions.SUCCESS(response));
    } catch (e) {
        dispatch(sendNoticeActions.FAIL({error: e}));
    }
}

const deleteNotice = (id) => async dispatch => {
    dispatch(deleteNoticeActions.INDEX());

    try {
        await api.deleteNotice(id);
        dispatch(deleteNoticeActions.SUCCESS());
    } catch (e) {
        dispatch(deleteNoticeActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_NOTICE_LIST, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.notice = action.payload.item;
            state.data.count = action.payload.count;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(GET_NOTICE, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.notice = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(SEND_NOTICE, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(DELETE_NOTICE, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    [SET_NOTICE]: (state, action) => {
        state.ui.notice = action.payload;
    },
    [CLEAR_NOTICE]: (state, action) => {
        state.ui.notice = {};
    },
});

export const NoticeActions = {
    getNoticeList,
    getNotice,
    sendNotice,
    deleteNotice,
    setNotice: createAction(SET_NOTICE),
    clearNotice: createAction(CLEAR_NOTICE),
};

export default reducer;