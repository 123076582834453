import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import api from "../lib/api";
import {createReducer} from "@reduxjs/toolkit";

const UPLOAD_IMAGE = 'image/UPLOAD_IMAGE';

const initialState = {
    ui: {
        isLoading: false,
    },
}

const uploadImageActions = makeAsyncActionCreator(UPLOAD_IMAGE);

const uploadImage = (file, type) => async dispatch => {
    dispatch(uploadImageActions.INDEX());

    try {
        const imageId = await api.uploadImage(file, type);
        dispatch(uploadImageActions.SUCCESS());
        return imageId;
    } catch (e) {
        dispatch(uploadImageActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(UPLOAD_IMAGE, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
        },
    }),
});

export const ImageActions = {
    uploadImage,
};

export default reducer;