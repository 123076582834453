import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {AppConfigsActions} from "../../store/AppConfigs";
import {LocationActions} from "../../store/Location";
import {BarActions} from "../../store/Bar";
import MaterialButton from "../MaterialButton";
import MaterialSelect from "../MaterialSelect";
import {MenuItem} from "@mui/material";
import MaterialTextField from "../MaterialTextField";
import {css} from "@emotion/react";
import colors from "../../styles/Colors";

const BarBasicInformationContainer = ({barImageState}) => {
    const dispatch = useDispatch();
    const {bar, categoryList, locationList} = useSelector((state) => ({
        bar: state.bar.ui.bar,
        categoryList: state.appConfigs.data.barCategory,
        locationList: state.location.data.location
    }));

    const [barImage, setBarImage] = barImageState;
    
    const nameRef = useRef(bar?.name);
    const addressRef = useRef(bar?.address);

    useEffect(() => {
        dispatch(AppConfigsActions.getBarCategory());
        dispatch(LocationActions.getLocation());
    }, [dispatch]);

    const getFileUrl = () => {
        if (barImage) return URL.createObjectURL(barImage)
        if (bar?.image_id) return process.env.REACT_APP_TIPXY_S3_URL + bar?.filename;
        return undefined;
    }

    const onFileUpdated = ({target}) => {
        setBarImage(target.files[0]);
    }

    const updateBar = () => {
        const newBar = {
            ...bar,
            name: nameRef.current.value,
            address: addressRef.current.value,
        };

        dispatch(BarActions.setBar(newBar));
    }

    const setCategory = ({target}) => {
        const newBar = {
            ...bar,
            category: target.value,
        };

        dispatch(BarActions.setBar(newBar));
    }

    const setLocation = ({target}) => {
        const newBar = {
            ...bar,
            location: target.value,
        };

        dispatch(BarActions.setBar(newBar));
    }

    return (
        <div css={basicInformationContainerStyle}>
            <MaterialButton
                css={thumbnailImageStyle(getFileUrl())} variant='contained' component='label'>
                <input type='file' accept='image/*' onChange={onFileUpdated} hidden/>
            </MaterialButton>
            <div css={basicInformationInputContainerStyle}>
                <div css={shopNameContainerStyle}>
                    <MaterialSelect css={{minWidth: 120}} value={bar?.category || 0} onChange={setCategory}>
                        {categoryList.map((e) => (
                            <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem>
                        ))}
                    </MaterialSelect>
                    <MaterialTextField css={{marginLeft: 4}} label='매장명' ref={nameRef}
                                       value={bar?.name || ''} onChange={updateBar} fullWidth/>
                </div>
                <MaterialTextField css={basicInformationInputStyle} label='주소' ref={addressRef}
                                   value={bar?.address || ''} onChange={updateBar} fullWidth/>
                <MaterialSelect css={basicInformationInputStyle} value={bar?.location || 0}
                                onChange={setLocation}>
                    {locationList.map((e) => (
                        <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                    ))}
                </MaterialSelect>
            </div>
        </div>
    );
}

const basicInformationContainerStyle = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (min-width: 865px) {
    flex-direction: row;
  }
`;

const thumbnailImageStyle = barImage => css`
  background: url(${barImage}) center center/cover;
  background-color: ${colors.font.dim100};
  width: 100%;
  padding-top: 200px;
  border-radius: 4px;

  &:hover {
    background: url(${barImage}) center center/cover;
    opacity: 0.8;
  }

  @media (min-width: 865px) {
    margin-right: 8px;
  }
`;

const basicInformationInputContainerStyle = css`
  @media (min-width: 865px) {
    width: 100%;
  }
`;

const basicInformationInputStyle = css`
  display: block;
  margin-top: 12px;
`;

const contactContainerStyle = css`
  display: flex;
  justify-content: end;
  align-content: space-between;
`;

const shopNameContainerStyle = css`
  ${basicInformationInputStyle};
  ${contactContainerStyle};
`;

export default BarBasicInformationContainer;