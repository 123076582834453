import MaterialTextField from "./MaterialTextField";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import WeekdayButton from "./bar/opening-hour/WeekdayButton";
import {useDispatch} from "react-redux";
import {BarActions} from "../store/Bar";
import colors from "../styles/Colors";
import {IconButton} from "@mui/material";
import DismissIcon from "../icons/DismissIcon";

const OpeningHourInput = ({openingHour = {}, onOpenTimeChange, onCloseTimeChange, onWeekdaysChange}) => {
    const weekdays = [64, 32, 16, 8, 4, 2, 1];

    const dispatch = useDispatch();
    const [tempOpeningHour, setTempOpeningHour] = useState(openingHour);

    const openTimeRef = useRef(openingHour.open_time);
    const closeTimeRef = useRef(openingHour.close_time);

    const isNew = openingHour?.id;

    useEffect(() => {
        dispatch(BarActions.setOpeningHour(tempOpeningHour));
    }, [tempOpeningHour]);

    const changeWeekday = (addWeekday) => {
        setTempOpeningHour((prevOpeningHour) => {
            const newWeekday = (prevOpeningHour.weekday ?? 0) + addWeekday;

            return ({
                ...prevOpeningHour,
                weekday: newWeekday,
            });
        });
    }

    const changeTime = () => {
        setTempOpeningHour((prevOpeningHour) => ({
            ...prevOpeningHour,
            open_time: openTimeRef.current.value,
            close_time: closeTimeRef.current.value,
        }));
    }

    const deleteOpeningHour = () => {
        setTempOpeningHour({});
        dispatch(BarActions.deleteOpeningHour(openingHour?.id));
    }

    return (
        <div css={rootStyle(isNew)}>
            <div css={weekdayButtonContainerStyle}>
                {weekdays.map(weekday => (
                    <WeekdayButton key={`${tempOpeningHour?.id}-${weekday}`} weekday={weekday}
                                   checked={(tempOpeningHour?.weekday & weekday) === weekday}
                                   onClick={changeWeekday}/>
                ))}
                <IconButton onClick={deleteOpeningHour}><DismissIcon/></IconButton>
            </div>
            <div css={timeInputContainerStyle}>
                <MaterialTextField css={timeInputStyle} defaultValue={tempOpeningHour.open_time} type='time'
                                   ref={openTimeRef} onChange={changeTime}/>
                <span css={timeDividerStyle}>~</span>
                <MaterialTextField css={timeInputStyle} defaultValue={tempOpeningHour.close_time} type='time'
                                   ref={closeTimeRef} onChange={changeTime}/>
            </div>
        </div>
    );
}

const rootStyle = isNew => css`
  padding: 12px 0;
  border-bottom: ${isNew ? `1px solid ${colors.font.dim200}` : `none`};
`;

const weekdayButtonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const timeInputContainerStyle = css`
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const timeInputStyle = css`
  flex-grow: 1;
`;

const timeDividerStyle = css`
  margin: 0 4px;
`

export default OpeningHourInput;