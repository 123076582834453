import MaterialTextField from "../MaterialTextField";
import MaterialButton from "../MaterialButton";
import CardView from "../CardView";
import React, {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {useDispatch, useSelector} from "react-redux";
import {UserActions} from "../../store/User";

const UserBarContainer = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.ui.user);

    const [barIdList, setBarIdList] = useState(user?.bar_id ?? []);
    const newBarIdRef = useRef();

    useEffect(() => {
        setBarIdList(user?.bar_id)
    }, [user?.bar_id]);

    const onChangeBarId = (index, value) => {
        setBarIdList((prevBarIdList) => {
            const copiedList = [...prevBarIdList]
            copiedList[index] = value ? parseInt(value) : undefined;

            return copiedList;
        })
    }

    const updateBarIdHandler = async () => {
        const newBarId = parseInt(newBarIdRef.current.value);
        const newBarIdList = [...barIdList];

        if (newBarId) {
            newBarIdList.push(newBarId);
        }

        await dispatch(UserActions.putUserBar(newBarIdList));
        newBarIdRef.current.value = '';
    }

    return (
        <CardView title={'매장 ID'}>
            <div>
                {user?.bar_id && user?.bar_id.map((e, index) =>
                    <MaterialTextField placeholder={`매장 삭제(${e})`} key={e} css={barIdInputStyle} type='number'
                                       defaultValue={e} onChange={({target}) => onChangeBarId(index, target.value)}/>
                )}
                <MaterialTextField placeholder={'ID 추가'} css={barIdInputStyle} type='number' ref={newBarIdRef}/>
            </div>
            <MaterialButton onClick={updateBarIdHandler}>적용하기</MaterialButton>
        </CardView>
    );
}

const barIdInputStyle = css`
  width: 100%;
  margin: 0 0 4px;
`;

export default UserBarContainer;