import {css} from "@emotion/react";
import colors from "../styles/Colors";
import buttonHoverStyle from "../styles/Styles";
import {Link} from "react-router-dom";
import {MOBILE_WIDTH} from "../Constants";

const buttonStyle = css`
  ${buttonHoverStyle};
  display: block;
  background-color: ${colors.background.light};
  padding: 12px;
  text-decoration: none;
  
  @media (min-width: ${MOBILE_WIDTH}) {
    background: ${colors.background.default};
  }
`

const MenuButton = ({to, children, setShowMenu, ...props}) => {
    const onLinkClickHandler = () => {
        setShowMenu(false)
    }

    return (
        <Link css={buttonStyle} to={to} onClick={onLinkClickHandler}>
            {children}
        </Link>
    )
}

export default MenuButton;