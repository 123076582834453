import {fetchDelete, fetchGet, getQueryString} from "../fetchLib";

export default {
    deleteApply: async (applyId) => {
        return await fetchDelete({
            url: `/api/private/apply`,
            body: {
                apply_id: applyId
            }
        });
    },
    getApplyList: async (query) => {
        const response = await fetchGet({
            url: `/api/private/apply/list${getQueryString(query)}`
        });

        return response;
    }
}