import SearchContainer from "../SearchContainer";
import MaterialSelect from "../MaterialSelect";
import {MenuItem} from "@mui/material";
import MaterialTextField from "../MaterialTextField";
import {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {NoticeActions} from "../../store/Notice";
import {MOBILE_WIDTH} from "../../Constants";
import {css} from "@emotion/react";

const NoticeSearchContainer = () => {
    const dispatch = useDispatch();
    const [searchType, setSearchType] = useState('id');
    const searchKeywordRef = useRef();

    const onSearchNoticeHandler = () => {
        const searchRequest = {
            type: searchType,
            keyword: searchKeywordRef.current.value,
        };

        dispatch(NoticeActions.getNoticeList(searchRequest));
    }

    return (
        <SearchContainer onSearch={onSearchNoticeHandler}>
            <MaterialSelect value={searchType} onChange={({target}) => setSearchType(target.value)}>
                <MenuItem value='id'>ID</MenuItem>
                <MenuItem value='title'>제목</MenuItem>
                <MenuItem value='content'>내용</MenuItem>
                <MenuItem value='all'>전체</MenuItem>
            </MaterialSelect>
            <MaterialTextField
                css={textFieldStyle}
                ref={searchKeywordRef}
                label='검색어'/>
        </SearchContainer>
    );
}


const textFieldStyle = css`
  margin: 12px 0 4px;

  @media (min-width: ${MOBILE_WIDTH}) {
    margin: 0 0 0 4px;
    flex-grow: 1;
  }
`;

export default NoticeSearchContainer;