import CardView from "../CardView";
import {Checkbox, FormControlLabel} from "@mui/material";
import MaterialButton from "../MaterialButton";
import {useDispatch, useSelector} from "react-redux";
import {AppConfigsActions} from "../../store/AppConfigs";

const AppConfigsSetting = () => {
    const dispatch = useDispatch();

    const appConfigs = useSelector((state) => state.appConfigs.data.configs)

    const configLabels = {
        blockChatRoom: '거리로 채팅방 접근 차단',
        showAd: '술집 목록 내 광고 표시',
        showChatRoom: '채팅방 입장 버튼 표시'
    };

    const configsChangeHandler = (key, value) => {
        dispatch(AppConfigsActions.updateAppConfigs({key, value}));
    }

    const updateConfigsHandler = () => {
        dispatch(AppConfigsActions.putAppConfigs());
    }

    return (
        <CardView title={'앱 기능 설정'}>
            {Object.entries(appConfigs).map(([key, value]) => (
                <FormControlLabel
                    key={key}
                    label={configLabels[key]}
                    checked={value === true}
                    control={<Checkbox/>}
                    onChange={() => configsChangeHandler(key, !value)}
                />
            ))}
            <MaterialButton onClick={updateConfigsHandler}>적용</MaterialButton>
        </CardView>
    );
}

export default AppConfigsSetting;