import {createAction, createReducer} from "@reduxjs/toolkit";
import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import api from "../lib/api";

const GET_APP_CONFIGS = 'configs/GET_APP_CONFIGS';
const GET_BAR_CATEGORY = 'configs/GET_BAR_CATEGORY';
const UPDATE_TOP_IMAGE = 'configs/UPDATE_TOP_IMAGE';
const UPDATE_APP_CONFIGS = 'configs/UPDATE_APP_CONFIGS';
const PUT_APP_CONFIGS = 'configs/PUT_APP_CONFIGS';

const initialState = {
    data: {
        topImage: undefined,
        configs: {
            blockChatRoom: false,
            showAd: false,
            showChatRoom: false,
        },
        barCategory: [],
    },
    ui: {
        isLoading: false,
    },
}

const getAppConfigsActions = makeAsyncActionCreator(GET_APP_CONFIGS);
const getBarCategoryActions = makeAsyncActionCreator(GET_BAR_CATEGORY);
const updateTopImageActions = makeAsyncActionCreator(UPDATE_TOP_IMAGE);
const putAppConfigsActions = makeAsyncActionCreator(PUT_APP_CONFIGS);

const getAppConfigs = () => async dispatch => {
    dispatch(getAppConfigsActions.INDEX());

    try {
        const response = await api.getAppConfigs();
        dispatch(getAppConfigsActions.SUCCESS(response.item));
    } catch (e) {
        dispatch(getAppConfigsActions.FAIL({error: e}));
    }
}

const getBarCategory = () => async (dispatch, state) => {
    dispatch(getBarCategoryActions.INDEX());

    try {
        let categories = state().appConfigs.data.barCategory;

        if (categories.length === 0) {
            const response = await api.getAppConfigs()
            categories = response.item.bar_category;
        }

        dispatch(getBarCategoryActions.SUCCESS(categories));
    } catch (e) {
        dispatch(getBarCategoryActions.FAIL({error: e}));
    }
}

const updateTopImage = file => async dispatch => {
    dispatch(updateTopImageActions.INDEX());

    try {
        await api.updateTopImage(file);

        const url = URL.createObjectURL(file);
        dispatch(updateTopImageActions.SUCCESS(url));
    } catch (e) {
        dispatch(updateTopImageActions.FAIL({error: e}));
    }
}

const putAppConfigs = () => async (dispatch, state) => {
    dispatch(putAppConfigsActions.INDEX());

    try {
        const {blockChatRoom: block_chat_room, showAd: show_ad, showChatRoom: show_chat_room} = state().appConfigs.data.configs;

        await api.updateAppConfigs({block_chat_room, show_ad, show_chat_room});
        dispatch(putAppConfigsActions.SUCCESS());
    } catch (e) {
        dispatch(putAppConfigsActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_APP_CONFIGS, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data = {
                topImage: action.payload.top_image,
                configs: {
                    blockChatRoom: action.payload.block_chat_room,
                    showAd: action.payload.show_ad,
                    showChatRoom: action.payload.show_chat_room,
                },
                barCategory: action.payload.bar_category,
            };

            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        }
    }),
    ...makeAsyncActionsHandler(GET_BAR_CATEGORY, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.barCategory = action.payload;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
        }
    }),
    ...makeAsyncActionsHandler(UPDATE_TOP_IMAGE, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.topImage = action.payload
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(PUT_APP_CONFIGS, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    [UPDATE_APP_CONFIGS]: (state, action) => {
        const {key, value} = action.payload;
        state.data.configs[key] = value;
    }
});

export const AppConfigsActions = {
    getAppConfigs,
    getBarCategory,
    updateTopImage,
    putAppConfigs,
    updateAppConfigs: createAction(UPDATE_APP_CONFIGS),
};

export default reducer;