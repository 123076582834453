import MaterialTextField from "../MaterialTextField";
import CardView from "../CardView";
import React, {useRef} from "react";
import {css} from "@emotion/react";
import {useDispatch, useSelector} from "react-redux";
import {BarActions} from "../../store/Bar";

const BarIntroduceContainer = () => {
    const dispatch = useDispatch();
    const bar = useSelector((state) => state.bar.ui.bar);

    const introduceOwnerRef = useRef(bar?.introduce_owner);
    const introduceCommentRef = useRef(bar?.introduce_comment);

    const updateBar = () => {
        const newBar = {
            ...bar,
            introduce_owner: introduceOwnerRef.current.value,
            introduce_comment: introduceCommentRef.current.value,
        };

        dispatch(BarActions.setBar(newBar));
    }

    return (
        <CardView>
            <span css={introduceLabelStyle}>한 줄 소개글</span>
            <MaterialTextField ref={introduceCommentRef} value={bar?.introduce_comment || ''} onChange={updateBar}/>
            <span css={introduceLabelStyle}>사장님 소개글</span>
            <MaterialTextField rows={4} ref={introduceOwnerRef} value={bar?.introduce_owner || ''} onChange={updateBar}
                               multiline/>
        </CardView>
    );
}

const introduceLabelStyle = css`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
`;

export default BarIntroduceContainer;