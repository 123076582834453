import CardView from "../CardView";
import MaterialButton from "../MaterialButton";
import TagDialog from "../Dialog/TagDialog";
import React, {useState} from "react";
import {useSelector} from "react-redux";

const BarTagContainer = () => {
    const tag = useSelector((state) => state.bar.ui.bar?.tag);

    const [openTagDialog, setOpenTagDialog] = useState(false);

    const onButtonClickHandler = () => {
        setOpenTagDialog(true);
    }

    const onTagDialogClose = () => {
        setOpenTagDialog(false);
    }

    return (
        <CardView title={'태그 관리'}>
            {tag?.map(e => e && (
                <span key={e}>{`#${e}`}</span>
            ))}
            <MaterialButton css={{marginTop: 12}} onClick={onButtonClickHandler}>태그 설정</MaterialButton>

            <TagDialog open={openTagDialog} onClose={onTagDialogClose}/>
        </CardView>
    );
}

export default BarTagContainer;