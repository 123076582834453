import {fetchGet, fetchPut} from "../fetchLib";

export default {
    getAppConfigs: async () => {
        const response = await fetchGet({
            url: `/api/public/config`
        });

        return response;
    },
    updateAppConfigs: async (request) => {
        const response = await fetchPut({
            url: `/api/public/config`,
            body: request,
        });

        return response.item;
    }
}