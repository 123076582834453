import MainLayout from "../../components/Layout/MainLayout";
import BarListMenu from "../../components/bar/BarListMenu";
import BarSearchContainer from "../../components/bar/BarSearchContainer";
import {useDispatch} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {BarActions} from "../../store/Bar";
import BarBoard from "../../components/bar/BarBoard";
import NoImageBarBoard from "../../components/bar-image/NoImageBarBoard";

const BarImageListPage = () => {
    const dispatch = useDispatch();
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const query = {
            limit: 10,
            ...Object.fromEntries(searchParams.entries()),
            only_image: 'N',
        };
        dispatch(BarActions.getNoImageBarList(query));
    }, [dispatch, searchParams]);

    return (
        <MainLayout title={'매장 사진 등록'} menu={<BarListMenu/>}>
            <BarSearchContainer/>
            <NoImageBarBoard/>
        </MainLayout>
    );
}

export default BarImageListPage;