import SearchContainer from "../SearchContainer";
import MaterialSelect from "../MaterialSelect";
import {MenuItem} from "@mui/material";
import MaterialTextField from "../MaterialTextField";
import {useRef, useState} from "react";
import {css} from "@emotion/react";
import {MOBILE_WIDTH} from "../../Constants";
import {useSearchParams} from "react-router-dom";

const UserSearchContainer = () => {
    const [searchType, setSearchType] = useState('id');
    const [searchParams, setSearchParams] = useSearchParams();
    const searchKeyword = useRef();

    const onSearchButtonClickHandler = () => {
        const params = Object.fromEntries(searchParams.entries());
        const searchRequest = {
            ...params,
            type: searchType,
            keyword: searchKeyword.current.value
        };

        setSearchParams(searchRequest);
    };

    return (
        <SearchContainer onSearch={onSearchButtonClickHandler}>
            <MaterialSelect value={searchType} onChange={({target}) => setSearchType(target.value)}>
                <MenuItem value='id'>ID</MenuItem>
                <MenuItem value='nickname'>닉네임</MenuItem>
                <MenuItem value='email'>이메일</MenuItem>
            </MaterialSelect>
            <MaterialTextField
                css={textFieldStyle}
                label='검색어'
                ref={searchKeyword}/>
        </SearchContainer>
    );
}

const textFieldStyle = css`
  margin: 12px 0 4px;

  @media (min-width: ${MOBILE_WIDTH}) {
    margin: 0 0 0 4px;
    flex-grow: 1;
  }
`;

export default UserSearchContainer;