import {Link} from "react-router-dom";
import MaterialButton from "../MaterialButton";

const NoticeMenuButtons = () => {
    return (
        <Link to={'write'}>
            <MaterialButton>공지 등록</MaterialButton>
        </Link>
    );
}

export default NoticeMenuButtons;