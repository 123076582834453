import {Dialog} from "@mui/material";
import MaterialButton from "../MaterialButton";
import {css} from "@emotion/react";

const buttonStyle = css`
  margin-left: 4px;
`;

const dialogButtonBarStyle = css`
  margin: auto;
  padding-bottom: 10px;
`;

const dialogTextStyle = css`
  margin: 20px;
  font-weight: 700;
`;

const DeleteDialog = ({open, onClose, closeDeleteDialogHandler, deleteHandler, ...props}) => {
    return (
        <Dialog open={open} onClose={onClose} {...props}>
            <span css={dialogTextStyle}>정말로 삭제하시겠습니까?</span>

            <div css={dialogButtonBarStyle}>
                <MaterialButton onClick={closeDeleteDialogHandler}>취소</MaterialButton>
                <MaterialButton css={buttonStyle} onClick={deleteHandler} color='warning'>확인</MaterialButton>
            </div>
        </Dialog>
    );
}

export default DeleteDialog;