import React, {useEffect, useRef, useState} from 'react';
import {Dialog, MenuItem} from "@mui/material";
import MaterialSelect from "../MaterialSelect";
import MaterialTextField from "../MaterialTextField";
import MaterialButton from "../MaterialButton";
import {css} from "@emotion/react";
import LoadingDialog from "./LoadingDialog";
import {useDispatch, useSelector} from "react-redux";
import api from "../../lib/api";
import {LocationActions} from "../../store/Location";

const buttonStyle = css`
  margin-left: 4px;
`;

const dialogButtonBarStyle = css`
  margin: auto;
  padding-bottom: 10px;
`;

const dialogTextStyle = css`
  margin: 20px;
  font-weight: 700;
`;

const addDialogInputContainerStyle = css`
  margin: 0 20px 8px;
  display: flex;
  flex-direction: column;
`;

const addDialogInputStyle = css`
  margin-bottom: 12px;
`;

const errorMessageStyle = css`
  color: red;
  margin: 0 auto 12px;
`

const AddShopDialog = ({shop = {}, open, onClose, onSuccess}) => {
    const dispatch = useDispatch();
    const locationList = useSelector(state => state.location.data.location);
    const [selectLocation, setSelectLocation] = useState(shop.location_name ? shop.location : 'null');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const locationNameRef = useRef(shop.location_name);
    const nameRef = useRef(shop.name);
    const addressRef = useRef(shop.address);

    useEffect(() => {
        dispatch(LocationActions.getLocation());
    }, [])

    const checkError = () => {
        setErrorMessage('');
        if (!((locationNameRef.current?.value ?? true)
            && nameRef.current.value
            && addressRef.current.value)) {
            setErrorMessage('입력되지 않은 항목이 있습니다.');
            return false;
        }

        return true;
    }

    const clickAddHandler = async () => {
        const request = {
            location: selectLocation === 'null' ? undefined : selectLocation,
            location_name: locationNameRef.current?.value,
            name: nameRef.current.value,
            address: addressRef.current.value,
        };

        setIsLoading(true);
        if (checkError()) {
            await api.createBar(request)
                .then(onSuccess)
                .catch(e => setErrorMessage(e.response.data.message));
        }
        setIsLoading(false);
    }

    const selectLocationHandler = (event) => {
        setSelectLocation(event.target.value);
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} fullWidth>
                <span css={dialogTextStyle}>아래 정보로 등록하시겠습니까?</span>

                <div css={addDialogInputContainerStyle}>
                    <MaterialSelect css={addDialogInputStyle} value={selectLocation} onChange={selectLocationHandler}>
                        {locationList.map((e) => (
                            <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                        ))}
                        <MenuItem value='null'>+ 지역 추가</MenuItem>
                    </MaterialSelect>
                    {selectLocation === 'null' &&
                        <MaterialTextField css={addDialogInputStyle} label={'지역명'} ref={locationNameRef}/>}
                    <MaterialTextField css={addDialogInputStyle} label={'매장명'} ref={nameRef} defaultValue={shop.name}/>
                    <MaterialTextField css={addDialogInputStyle} label={'주소'} ref={addressRef}
                                       defaultValue={shop.address}/>
                </div>

                <span css={errorMessageStyle}>{errorMessage}</span>

                <div css={dialogButtonBarStyle}>
                    <MaterialButton onClick={onClose}>취소</MaterialButton>
                    <MaterialButton onClick={clickAddHandler} css={buttonStyle}>등록</MaterialButton>
                </div>
            </Dialog>

            <LoadingDialog open={isLoading}/>
        </>
    );
}

export default AddShopDialog;