import CardView from "../CardView";
import MaterialButton from "../MaterialButton";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {css} from "@emotion/react";
import OpeningHourDialog from "../Dialog/OpeningHourDialog";

const BarOpeningHourContainer = () => {
    const openingHours = useSelector((state) => state.bar.ui.bar?.opening_hours || []);

    const [openOpeningHourDialog, setOpenOpeningHourDialog] = useState(false);

    const onCloseOpeningHourDialog = () => {
        setOpenOpeningHourDialog(false);
    }

    return (
        <CardView title={'영업시간 관리'}>
            {openingHours?.map((e) => (
                <span key={e} css={openingHourStyle}>{e}</span>
            )) ?? <span css={openingHourStyle}>정보 없음</span>}
            <MaterialButton css={{marginTop: 12}} onClick={() => setOpenOpeningHourDialog(true)}>영업시간 수정</MaterialButton>

            <OpeningHourDialog open={openOpeningHourDialog} onClose={onCloseOpeningHourDialog}/>
        </CardView>
    );
}

const openingHourStyle = css`
  margin: 4px 0;
`;

export default BarOpeningHourContainer;