import apply from "./apply";
import auth from "./auth";
import bar from "./bar";
import chat from "./chat";
import image from "./image";
import notice from "./notice";
import user from "./user";
import config from "./config";

export default {
    ...apply,
    ...auth,
    ...bar,
    ...chat,
    ...config,
    ...image,
    ...notice,
    ...user,
}