import MenuButton from "./MenuButton";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {MOBILE_WIDTH} from "../Constants";

const menuStyle = (isShowing) => css`
  width: 100%;
  display: ${isShowing ? 'block' : 'none'};
  position: absolute;
  z-index: 999;
  
  @media(min-width: ${MOBILE_WIDTH}) {
    width: 180px;
    height: 100vh;
    background: ${colors.background.default};
    display: block;
    position: sticky;
    top: 0;
  }
`

const SideMenu = ({showMenu, setShowMenu}) => {
    return (
        <div css={menuStyle(showMenu)}>
            <MenuButton to='configs' setShowMenu={setShowMenu}>앱 기본 설정</MenuButton>
            <MenuButton to='notice' setShowMenu={setShowMenu}>공지사항</MenuButton>
            <MenuButton to='user' setShowMenu={setShowMenu}>회원 관리</MenuButton>
            <MenuButton to='bar' setShowMenu={setShowMenu}>매장 관리</MenuButton>
            <MenuButton to='chat' setShowMenu={setShowMenu}>채팅 관리</MenuButton>
            <MenuButton to='apply' setShowMenu={setShowMenu}>제보 관리</MenuButton>
            <MenuButton to='image' setShowMenu={setShowMenu}>매장 사진 등록</MenuButton>
        </div>
    )
}

export default SideMenu;