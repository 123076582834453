import {useDispatch, useSelector} from "react-redux";
import MaterialBoard from "../MaterialBoard";
import moment from "moment/moment";
import ApplyBarDialog from "../Dialog/ApplyBarDialog";
import React, {useState} from "react";
import {ApplyActions} from "../../store/Apply";

const ApplyBoard = () => {
    const dispatch = useDispatch();
    const {apply, count, isLoading} = useSelector((state) => ({
        apply: state.apply.data.apply,
        count: state.apply.data.count,
        isLoading: state.apply.ui.isLoading,
    }));
    const [open, setOpen] = useState(false);

    const dateFormatter = ({value}) => moment(value).format("YYYY.MM.DD");

    const clickRowHandler = ({row}) => {
        dispatch(ApplyActions.setApply(row));
        setOpen(true);
    }

    const onDialogClose = () => {
        setOpen(false);
    }

    const columns = [
        {field: 'id', headerName: 'ID', type: 'number', width: 64},
        {field: 'created_time', headerName: '제보일', type: 'date', width: 100, valueFormatter: dateFormatter},
        {field: 'location_name', headerName: '지역', type: 'string'},
        {field: 'name', headerName: '매장명', type: 'string', width: 150},
        {field: 'address', headerName: '주소', type: 'string', minWidth: 200, flex: 1},
        {field: 'email', headerName: '제보자', type: 'string', minWidth: 200, flex: 1},
    ];

    return (
        <>
            <MaterialBoard columns={columns} rows={apply} count={count} isLoading={isLoading}
                           onRowClick={clickRowHandler}/>
            <ApplyBarDialog open={open} onClose={onDialogClose}/>
        </>
    );
}

export default ApplyBoard;