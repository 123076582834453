import MaterialBoard from "../MaterialBoard";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Avatar} from "@mui/material";
import colors from "../../styles/Colors";
import moment from "moment/moment";

const UserBoard = () => {
    const navigate = useNavigate();
    const {user, count, isLoading} = useSelector((state) => ({
        user: state.user.data.user,
        count: state.user.data.count,
        isLoading: state.user.ui.isLoading,
    }));

    const renderProfileCell = (params) => {
        if (params.value?.includes('/')) return (
            <Avatar src={process.env.REACT_APP_TIPXY_S3_URL + params.value}/>
        )

        return (
            <Avatar sx={{bgcolor: colors.profileBgColor[params.id % colors.profileBgColor.length]}}>
                {params.value}
            </Avatar>
        );
    }

    const dateFormatter = ({value}) => moment(value).format('yyyy.MM.DD');
    const typeFormatter = ({value}) => ['일반 회원', '사장님', '관리자'][value];

    const clickRowHandler = ({row}) => {
        navigate(`${row.id}`, {state: row.row});
    }

    const columns = [
        {field: 'id', headerName: 'ID', type: 'number', width: 64},
        {field: 'profile', headerName: '프로필', type: 'string', width: 56, renderCell: renderProfileCell},
        {field: 'created_time', headerName: '가입일', type: 'date', valueFormatter: dateFormatter, width: 100},
        {field: 'nickname', headerName: '닉네임', type: 'string', width: 130},
        {field: 'social_name', headerName: '가입 유형', type: 'string'},
        {field: 'email', headerName: '이메일', type: 'string', minWidth: 180, flex: 1},
        {field: 'type', headerName: '회원 유형', type: 'string', valueFormatter: typeFormatter},
    ];

    return (
        <MaterialBoard columns={columns} rows={user} count={count} isLoading={isLoading} onRowClick={clickRowHandler}/>
    );
}

export default UserBoard;