import {fetchDelete, fetchGet, fetchPost, fetchPut, getQueryString} from "../fetchLib";

export default {
    getBarLocationList: async () => {
        const response = await fetchGet({
            url: `/api/public/bar/location`
        });

        return response.item;
    },
    updateLocation: async (request) => {
        const response = await fetchPut({
            url: `/api/private/bar/location`,
            body: request
        });

        return response.item;
    },
    getBarCategoryList: async () => {
        const response = await fetchGet({
            url: `/api/public/bar/categories`
        });

        return response.item;
    },
    getBar: async (bar_id) => {
        const response = await fetchGet({
            url: `/api/private/bar${getQueryString({bar_id})}`
        });

        return response.item;
    },
    getBarList: async (request) => {
        const response = await fetchGet({
            url: `/api/public/bar/list${getQueryString(request)}`
        });

        return response;
    },
    createBar: async (request) => {
        const response = await fetchPost({
            url: `/api/private/bar`,
            body: request
        });

        return response;
    },
    updateBar: async (request) => {
        const response = await fetchPut({
            url: `/api/private/bar`,
            body: request
        });

        return response;
    },
    deleteBar: async (bar_id) => {
        const response = await fetchDelete({
            url: `/api/private/bar`,
            body: {bar_id}
        });

        return response;
    },
    updateBarOwner: async (request) => {
        const response = await fetchPut({
            url: `/api/private/admin/bar/owner`,
            body: request
        });

        return response;
    },
    updateBarMenu: async (request) => {
        const response = await fetchPut({
            url: `/api/private/bar/menu`,
            body: request
        });

        return response;
    },
    updateBarTag: async (request) => {
        const response = await fetchPut({
            url: `/api/private/bar/tag`,
            body: request
        });

        return response;
    },
    getOpeningHours: async (bar_id) => {
        const response = await fetchGet({
            url: `/api/public/bar/opening-hours${getQueryString({bar_id})}`
        });

        return response.item;
    },
    createOpeningHours: async (request) => {
        const response = await fetchPost({
            url: `/api/private/bar/opening-hours`,
            body: request
        });

        return response;
    },
    deleteOpeningHours: async (bar_id) => {
        const response = await fetchDelete({
            url: `/api/private/bar/opening-hours`,
            body: {bar_id}
        });

        return response;
    }
}