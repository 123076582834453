import MainLayout from "../../components/Layout/MainLayout";
import {useEffect} from "react";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import {useDispatch, useSelector} from "react-redux";
import AppTopImage from "../../components/app-configs/AppTopImage";
import {AppConfigsActions} from "../../store/AppConfigs";
import AppConfigsSetting from "../../components/app-configs/AppConfigsSetting";
import LocationSetting from "../../components/app-configs/LocationSetting";

const AppConfigsPage = () => {
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.appConfigs.ui.isLoading);

    useEffect(() => {
        dispatch(AppConfigsActions.getAppConfigs());
    }, []);

    return (
        <MainLayout title='앱 기본 설정'>
            <AppTopImage/>
            <AppConfigsSetting/>
            <LocationSetting/>
            <LoadingDialog open={isLoading}/>
        </MainLayout>
    )
}

export default AppConfigsPage;