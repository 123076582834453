import MainLayout from "../../components/Layout/MainLayout";
import NoticeMenuButtons from "../../components/notice/NoticeMenuButtons";
import NoticeSearchContainer from "../../components/notice/NoticeSearchContainer";
import {useDispatch, useSelector} from "react-redux";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import NoticeBoard from "../../components/notice/NoticeBoard";
import {useEffect} from "react";
import {NoticeActions} from "../../store/Notice";

const NoticeListPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(NoticeActions.getNoticeList());
    }, []);

    return (
        <MainLayout title='공지사항' menu={<NoticeMenuButtons/>}>
            <NoticeSearchContainer/>
            <NoticeBoard/>
        </MainLayout>
    );
}

export default NoticeListPage;