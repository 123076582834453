import MaterialButton from "../MaterialButton";
import AddShopDialog from "../Dialog/AddShopDialog";
import React, {useState} from "react";

const BarListMenu = () => {
    const [openAddShopDialog, setOpenAddShopDialog] = useState(false);

    return (
        <>
            <MaterialButton onClick={() => setOpenAddShopDialog(true)}>매장 등록</MaterialButton>
            <AddShopDialog open={openAddShopDialog} onClose={() => setOpenAddShopDialog(false)}/>
        </>
    );
}

export default BarListMenu;