import MainLayout from "../../components/Layout/MainLayout";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {UserActions} from "../../store/User";
import UserPageButtons from "../../components/user/UserPageButtons";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import UserInformationContainer from "../../components/user/UserInformationContainer";
import UserBarContainer from "../../components/user/UserBarContainer";
import UserDetailContainer from "../../components/user/UserDetailContainer";
import UserChangePasswordContainer from "../../components/user/UserChangePasswordContainer";

const UserPage = () => {
    const dispatch = useDispatch();
    const {state} = useLocation();
    const {userId} = useParams();

    const {user, isLoading} = useSelector((state) => ({
        user: state.user.ui.user,
        isLoading: state.user.ui.isLoading,
    }));

    useEffect(() => {
        if (state) {
            dispatch(UserActions.setUser(state));
            return;
        }

        dispatch(UserActions.getUser(userId));
    }, []);

    const onUserDelete = () => {
        dispatch(UserActions.deleteUser(user?.id));
    }

    const onUserUpdate = () => {
        dispatch(UserActions.putUser(user));
    }

    return (
        <MainLayout title={`#${user?.id}`} menu={<UserPageButtons onDelete={onUserDelete} onUpdate={onUserUpdate}/>}>
            <UserInformationContainer/>
            {user?.type === 1 && <UserBarContainer/>}
            <UserDetailContainer/>
            <UserChangePasswordContainer/>
            <LoadingDialog open={isLoading}/>
        </MainLayout>
    );
}

export default UserPage;