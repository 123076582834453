import {Dialog} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BarActions} from "../../store/Bar";
import OpeningHourInput from "../OpeningHourInput";
import MaterialButton from "../MaterialButton";
import {css} from "@emotion/react";

const OpeningHourDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const {bar, openingHour, error} = useSelector((state) => ({
        bar: state.bar.ui.bar || {},
        openingHour: state.bar.ui.openingHour,
        error: state.bar.error.openingHour,
    }));

    useEffect(() => {
        if (!bar.id) return;

        if (open) {
            dispatch(BarActions.getOpeningHour(bar.id));
        }
    }, [bar, open]);

    const onSubmitClick = async () => {
        const response = await dispatch(BarActions.putOpeningHour(bar.id));

        if (!response) {
            onClose();
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <div css={{padding: 12}}>
                {openingHour.map((e, index) => e.id && (
                    <OpeningHourInput key={e.id} openingHour={e}/>
                ))}
                <OpeningHourInput/>
                <div css={errorStyle}>{error}</div>
                <MaterialButton css={{marginTop: 12}} onClick={onSubmitClick} fullWidth>적용</MaterialButton>
            </div>
        </Dialog>
    )
}

const errorStyle = css`
  text-align: center;
  color: red;
`;

export default OpeningHourDialog;{}