import {makeAsyncActionCreator, makeAsyncActionsHandler} from "./makeAsyncActionsUtils";
import api from "../lib/api";
import {createAction, createReducer} from "@reduxjs/toolkit";

const GET_APPLY_LIST = 'apply/GET_APPLY_LIST';
const DELETE_APPLY = 'apply/DELETE_APPLY';
const SET_APPLY = 'apply/SET_APPLY';

const initialState = {
    data: {
        apply : [],
        count: 0,
    },
    ui: {
        apply: {},
        isLoading: false,
    },
};

const getApplyListActions = makeAsyncActionCreator(GET_APPLY_LIST);
const deleteApplyActions = makeAsyncActionCreator(DELETE_APPLY);

const getApplyList = (query) => async dispatch => {
    dispatch(getApplyListActions.INDEX());

    try {
        const response = await api.getApplyList(query);
        dispatch(getApplyListActions.SUCCESS(response));
    } catch (e) {
        dispatch(getApplyListActions.FAIL({error: e}));
    }
}

const deleteApply = (id) => async dispatch => {
    dispatch(deleteApplyActions.INDEX());

    try {
        await api.deleteApply(id);
        dispatch(deleteApplyActions.SUCCESS());
    } catch (e) {
        dispatch(deleteApplyActions.FAIL({error: e}));
    }
}

const reducer = createReducer(initialState, {
    ...makeAsyncActionsHandler(GET_APPLY_LIST, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.data.apply = action.payload.item;
            state.data.count = action.payload.count;
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    ...makeAsyncActionsHandler(DELETE_APPLY, {
        onRequest: (state, action) => {
            state.ui.isLoading = true;
        },
        onSuccess: (state, action) => {
            state.ui.isLoading = false;
        },
        onFail: (state, action) => {
            state.ui.isLoading = false;
            console.log('@@ action.payload.error', action.payload.error);
        },
    }),
    [SET_APPLY]: (state, action) => {
        state.ui.apply = action.payload;
    }
});

export const ApplyActions = {
    getApplyList,
    deleteApply,
    setApply: createAction(SET_APPLY),
};

export default reducer;