import LogoIcon from "../icons/LogoIcon";
import {css} from "@emotion/react";
import colors from "../styles/Colors";
import {Link, useNavigate} from "react-router-dom";
import MenuIcon from "../icons/MenuIcon";
import buttonHoverStyle from "../styles/Styles";
import {MOBILE_WIDTH} from "../Constants";

const menuButtonStyle = css`
  background: transparent;
  ${buttonHoverStyle};

  @media (min-width: ${MOBILE_WIDTH}) {
    display: none;
  }
`

const topBarStyle = css`
  display: flex;
  min-width: 255px;
  justify-content: space-between;
  background: ${colors.primary};
  align-items: stretch;
`

const logoContainerStyle = css`
  @media (min-width: ${MOBILE_WIDTH}) {
    padding: 12px 16px;
  }
  
  & svg {
    height: 100%;
  }
`

const signOutButtonStyle = css`
  ${buttonHoverStyle};
  height: 100%;
  padding: 0 12px;
  background-color: transparent;
  color: ${colors.font.default};
`

const TopBar = ({menuButtonClickHandler}) => {
    const navigate = useNavigate();
    const clickSignOutHandler = () => {
        localStorage.clear();
        navigate('/');
    }

    return (
        <div css={topBarStyle}>
            <button css={menuButtonStyle} onClick={menuButtonClickHandler}>
                <MenuIcon/>
            </button>
            <div css={logoContainerStyle}>
                <Link to='/configs'>
                    <LogoIcon/>
                </Link>
            </div>
            <div>
                <button css={signOutButtonStyle} onClick={clickSignOutHandler}>
                    로그아웃
                </button>
            </div>
        </div>
    );
}

export default TopBar;