import {Route, Routes, useNavigate} from "react-router-dom";
import AppConfigsPage from "./app-configs/AppConfigsPage";
import TopBarLayout from "../components/Layout/TopBarLayout";
import ApplyListPage from "./apply/ApplyListPage";
import UserListPage from "./user/UserListPage";
import ChatRoomListPage from "./chat/ChatRoomListPage";
import ChatRoomPage from "./chat/ChatRoomPage";
import {useEffect} from "react";
import {STORAGE_USER} from "../Constants";
import NoticeListPage from "./notice/NoticeListPage";
import NoticePage from "./notice/NoticePage";
import UserPage from "./user/UserPage";
import BarListPage from "./bar/BarListPage";
import BarPage from "./bar/BarPage";
import BarImageListPage from "./bar-image/BarImageListPage";

const MainPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const userId = localStorage.getItem(STORAGE_USER.USER_ID);

        if (!userId) {
            navigate('/');
            return;
        }
    }, []);

    return (
        <TopBarLayout>
            <Routes>
                <Route path='/configs' element={<AppConfigsPage/>}/>
                <Route path='/notice' element={<NoticeListPage/>}/>
                <Route path='/notice/write' element={<NoticePage/>}/>
                <Route path='/notice/:noticeId' element={<NoticePage/>}/>
                <Route path='/user' element={<UserListPage/>}/>
                <Route path='/user/:userId' element={<UserPage/>}/>
                <Route path='/bar' element={<BarListPage/>}/>
                <Route path='/bar/:barId' element={<BarPage/>}/>
                <Route path='/chat' element={<ChatRoomListPage/>}/>
                <Route path='/chat/:chatRoomId' element={<ChatRoomPage/>}/>
                <Route path='/apply' element={<ApplyListPage/>}/>
                <Route path='/image' element={<BarImageListPage/>}/>
            </Routes>
        </TopBarLayout>
    );
}

export default MainPage;